import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { message, Input, Form, Button } from 'antd'
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import Pager from '../../components/Pager';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import '../MyNotesAddress/index.css'

const { Search, TextArea } = Input;

function Add({ user, show, onHide }) {
  const [fields, setFields] = useState({
    name: '',
    contract_address: '',
    abi: ''
  });
  const [addResult, setAddResult] = useState({});
  const abiCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/customAbi/abiCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleCustomAbi = () => {
    abiCreateRequest.run(fields).then(res => {
      if (res.code == 0) {
        setAddResult(res)
        setFields({
          name: '',
          contract_address: '',
          abi: ''
        })
        setTimeout(() => {
          onHide();
          setAddResult({})
        }, 1500);
      }
    });
  };
  const showError = typeof abiCreateRequest.data?.code !== 'undefined' && abiCreateRequest.data?.code !== 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Add a new Custom ABI</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong> Successfully Created New Custom ABI.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {abiCreateRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="FormName">Name</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtFormName"
                type="text"
                maxLength="100"
                id="ContentPlaceHolder1_txtFormName"
                className="form-control"
                placeholder="e.g. Contract Name"
                required=""
                data-msg="Please enter name"
                data-error-class="u-has-error"
                value={fields.name}
                onChange={e => {
                  setFields({ ...fields, name: e.target.value });
                }}
                data-success-class="u-has-success" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="FormAddress">Address</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtFormContractAddress"
                type="text"
                maxLength="42"
                id="ContentPlaceHolder1_txtFormContractAddress"
                className="form-control"
                placeholder="0x..."
                required=""
                data-msg="Please enter contract address"
                data-error-class="u-has-error"
                value={fields.contract_address}
                onChange={e => {
                  setFields({ ...fields, contract_address: e.target.value });
                }}
                data-success-class="u-has-success" />
              <span id="ContentPlaceHolder1_revtxtFormContractAddress" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }}>*Invalid Address</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="FormCustomABI">Custom ABI</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <textarea name="ctl00$ContentPlaceHolder1$txtFormCustomABI" rows="10" cols="20" id="ContentPlaceHolder1_txtFormCustomABI" className="form-control" placeholder="[{...}]" required="" data-msg="Please enter custom ABI" data-error-class="u-has-error" data-success-class="u-has-success"
                value={fields.abi}
                onChange={e => {
                  setFields({ ...fields, abi: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleCustomAbi} />
      </Modal.Footer>
    </Modal>
  )
}

function Edit({ data = {}, user, show, onHide }) {
  const [fields, setFields] = useState({
    custom_id: data.custom_id || '',
    name: data.name || '',
    abi: data.abi || ''
  });
  const [editResult, setEditResult] = useState({});
  const abiEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/customAbi/abiEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const editConfirm = () => {
    abiEditRequest.run(fields).then(res => {
      if (res.code == 0) {
        setEditResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    });
  };

  const showError = typeof abiEditRequest.data?.code !== 'undefined' && abiEditRequest.data?.code !== 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title> Edit a new Custom ABI</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully Updated APIKey {data.api_key_token} options
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {abiEditRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Address">&nbsp;Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtAddress"
                type="text"
                maxLength="44"
                id="ContentPlaceHolder1_txtAddress"
                className="form-control"
                required=""
                data-msg="Please enter a valid address"
                data-error-class="u-has-error"
                data-success-class="u-has-success"
                placeholder="Enter a address"
                value={data.contract_address}
                readOnly />
              <span id="ContentPlaceHolder1_rfvAddress" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }}><br /> *Invalid address</span>
              <p></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="FormName">Name</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtFormName"
                type="text"
                maxLength="100"
                id="ContentPlaceHolder1_txtFormName"
                className="form-control"
                placeholder="e.g. Contract Name"
                required=""
                data-msg="Please enter name"
                data-error-class="u-has-error"
                value={fields.name}
                onChange={e => {
                  setFields({ ...fields, name: e.target.value });
                }}
                data-success-class="u-has-success" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Txnhash">&nbsp;Custom ABI :</label>
            <div className="input-group input-group-sm col-sm-12">
              <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea" rows="2" cols="20" id="ContentPlaceHolder1_txtPrivateNoteArea" spellCheck="false" className="form-control"
                value={fields.abi}
                onChange={e => {
                  setFields({ ...fields, abi: e.target.value });
                }}
              />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip"></span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Save Changes" className="btn btn-sm btn-primary btn-xs" onClick={editConfirm} />
      </Modal.Footer>
    </Modal>
  )
}

function Remove({ data = {}, user, show, onHide }) {
  const abiDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/customAbi/abiDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleAddTxnNotes = () => {
    abiDeleteRequest.run({ custom_id: data.custom_id }).then(res => {
      if (res.code == 0) {
        onHide()
      } else {
        message.error(res.enMsg)
      }
    })
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <p>Are you sure you wish to remove the Custom ABI?<br />
            <span className="text-secondary font-weight-normal" id="removetxhash">{data.contract_address}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Close
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddTxnNotes} />
      </Modal.Footer>
    </Modal>
  )
}

function MycustomabiList({ user, update, filter }) {
  const [showView, setShowView] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [curCustomAbi, setCurCustomAbi] = useState();

  const body = {
    start: '1',
    length: '50',
    filter: filter || ''
    // field: 'key_name',
    // value: '',
  }

  const listCustomAbiRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/customAbi/abiList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    listCustomAbiRequest.run(body);
  }, [update, filter]);

  const data = listCustomAbiRequest.data?.custom_abi_list || [];
  const counts = listCustomAbiRequest.data?.counts || 0;
  const limit_counts = listCustomAbiRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / body.length);

  const handleCreateHide = () => {
    listCustomAbiRequest.run(body);
    setShowView(false);
  };
  const handleCreateHideRemove = () => {
    listCustomAbiRequest.run(body);
    setRemoveView(false);
  };

  return (
    <div className="card-body">
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">&nbsp;{counts} used (Out of {limit_counts} max quota)</p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={body.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure id="SVGdataReport1" className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Title</th>
                  <th scope="col">Address</th>
                  <th scope="col">Created</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top">
                          <a
                            data-toggle="modal"
                            data-target="#responsive"
                            className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                            rel="tooltip"
                            title=""
                            onClick={() => {
                              setShowView(!showView);
                              setCurCustomAbi(item)
                            }}
                            // onClick="javascript:getnote('0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711');"
                            data-original-title="View/Update TxNote"
                          >
                            Edit
                          </a>
                          <a
                            data-toggle="modal"
                            data-target="#myModaldelete"
                            className="btn btn-outline-secondary btn-xss html-link-custom"
                            onClick={() => {
                              setRemoveView(!removeView);
                              setCurCustomAbi(item)
                            }}
                            // onClick="javascript:getaddress('0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711');"
                            rel="tooltip"
                            title=""
                            data-original-title="Remove TxNote"
                          >
                            Remove
                          </a>
                        </td>
                        <td className="align-top">{item.name}</td>
                        <td className="align-top">
                          <span className="d-block">
                            <a href={`/address/${item.contract_address}`}>
                              <span className="text-primary">{item.contract_address}</span>
                            </a>
                          </span>
                          {/* <span className="d-block small text-break" id={item.note_id}>
                            {item.note}
                          </span> */}
                        </td>
                        <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                      </tr>
                    )
                  })
                ) : listCustomAbiRequest.loading ? (
                  <tr />
                ) : (
                  <tr>
                    <td colSpan="4" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">You have not entered any custom ABIs</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      {showView && <Edit data={curCustomAbi} show={showView} user={user} onHide={handleCreateHide} />}
      <Remove show={removeView} data={curCustomAbi} user={user} onHide={handleCreateHideRemove} />
    </div>
  )
}

export default function MyCustomAbi() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isFlag, setIsFlag] = useState(false);

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mycustomabi" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Custom ABIs</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a
                      href="#"
                      id="ContentPlaceHolder1_addnew"
                      data-toggle="modal"
                      data-target="#myModal"
                      rel="tooltip"
                      title=""
                      className="btn btn-xss btn-primary rounded"
                      onClick={() => {
                        setShow(!show);
                      }}
                      data-original-title="Add a custom ABI"
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  {searchStr && <div className="btn-group order-2 order-md-1 ml-2 mr-md-2" role="group">
                    <span className='btn btn-xss btn-soft-secondary ml-auto'>Filtered by
                      <span className='hash-tag hash-tag text-truncate font-weight-bold ml-2'>{searchStr}</span>
                    </span>
                    <a className="btn btn-sm btn-icon btn-soft-secondary" href="javascript:;"
                      onClick={() => setSearchStr('')}
                    >
                      <i className='fa fa-times btn-icon__inner' />
                    </a>
                  </div>}
                  <div className="position-relative order-1 order-md-2">
                    <a
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      onClick={() => setIsFlag(!isFlag)}
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    {isFlag && <div className="searchBox">
                      <Search
                        placeholder="Search Contract Address/Name"
                        allowClear
                        enterButton="Find"
                        size="large"
                        onSearch={(value) => {
                          setSearchStr(value)
                          setIsFlag(!isFlag)
                        }}
                      />
                    </div>}
                  </div>
                </div>
              </div>
              <MycustomabiList user={user} update={show} filter={searchStr} />
            </div>
          </div>
          <Add user={user} show={show} onHide={() => setShow(!show)} />
        </div>
      </div>
    </main>
  );
}
