import React from 'react';
import ReactDOM from 'react-dom';
import {
    GoogleReCaptchaProvider,
  } from 'react-google-recaptcha-v3';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './locales';

import './style.css';

ReactDOM.render(
    <GoogleReCaptchaProvider language='en' reCaptchaKey="6Ld91-YkAAAAAKHrSZumWemhDAGdBMDV032YKHQM">
      <App />
    </GoogleReCaptchaProvider>,
    document.getElementById('root')
  );
// ReactDOM.render(, document.getElementById('root'));

reportWebVitals();
