import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Button, message } from 'antd';
import { useLocation } from 'react-router';
import { MailFilled } from '@ant-design/icons';
import qs from 'qs';

let timer;

export default function PromptActive() {
    const location = useLocation();
    const [userEmail, setUserEmail] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [time, setTime] = useState(30)
    const [key, setKey] = useState('');
    const [code, setCode] = useState(null);
    const [btnContent, setBtnContent] = useState('获取验证码')


    useEffect(() => {
        clearInterval(timer)
        return () => clearInterval(timer)
    }, [])
    useEffect(() => {
        if (time > 0 && time < 30) {
            setBtnContent(`${time}s`)
        } else {
            clearInterval(timer)
            setBtnDisabled(false)
            setTime(30)
        }
    }, [time])

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        let _email = query.email
        const reg = /^(?=\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,64}$/
        if (!reg.test(_email)) {
            window.location.href = '/'
        }
        query.email && setUserEmail(query.email)
        setKey(window.sessionStorage.getItem('key'))
    }, [])

    const activateResend = useRequest(
        body => ({
            url: '/blockBrowser/user/login/activateResend',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const handleBtnClick = () => {
        timer = setInterval(() => setTime(pre => pre - 1), 1000)
        setBtnDisabled(true)

        activateResend.run({ key }).then(res => {
            if (res.code == 10083) {
                setCode(res.code)
                message.warning(res.enMsg)
                clearInterval(timer)
                setBtnDisabled(false)
            }
        });
    }
    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className="w-lg-50 mx-md-auto">
                    <div className='mb-6 d-flex justify-content-center'>
                        <MailFilled style={{ fontSize: '50px', color: '#777' }} />
                        <div className='ml-4'>
                            <div className='font-weight-bold mb-2'>Please verify your Email</div>
                            <div className='d-block mb-2'>In order to use CMPScan, you must verify your email address.</div>
                            <div className='d-block'>Please check your email({userEmail}).</div>
                        </div>
                    </div>
                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                        <div>Didn't receive the mail? You can:</div>
                        <Button type='primary' className='mb-4 mt-2' disabled={btnDisabled} onClick={handleBtnClick}>
                            {!btnDisabled ? 'Resend Email' : `Resend Email ${time}s`}
                        </Button><br />
                        <a className="text-nowrap" href={`/changeEmail?email=${userEmail}`}>Change Email Address</a>
                        {code == 10083 && (<><br /><a href={`/login`}>Login</a></>)}
                    </div>
                </div>
            </div>
        </main>
    )
}