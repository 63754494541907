import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { message, Input, Typography } from 'antd';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';
import './index.css'

const { Search } = Input;
const { Paragraph } = Typography;

function Augment({ user, show, onHide }) {
  const [fields, setFields] = useState({
    address: '',
    tag: '',
    note: ''
  });
  const [addResult, setAddResult] = useState({});
  const adsNoteCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleNotesAddress = () => {
    adsNoteCreateRequest.run(fields).then(res => {
      if (res.code == 0) {
        setAddResult(res)
        setFields({
          address: '',
          tag: '',
          note: ''
        })
        setTimeout(() => {
          onHide();
          setAddResult({})
        }, 1500);
      } else {
        setFields({
          address: '',
          tag: '',
          note: ''
        })
        setAddResult({})
      }
    });
  };
  const showError = typeof adsNoteCreateRequest.data?.code !== 'undefined' && adsNoteCreateRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Add a new address note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully Created New Address Note.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {adsNoteCreateRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Address">&nbsp;Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtAddress"
                type="text"
                maxLength="44"
                id="ContentPlaceHolder1_txtAddress"
                className="form-control"
                required=""
                data-msg="Please enter a valid address"
                data-error-class="u-has-error"
                data-success-class="u-has-success"
                value={fields.address}
                onChange={e => {
                  setFields({ ...fields, address: e.target.value });
                }}
                placeholder="Enter a address" />
              <span id="ContentPlaceHolder1_rfvAddress" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }} ><br /> *Invalid address</span>
              <p></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="nameTag">&nbsp;Name Tag :</label>
            <div className="input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtPrivateNameTag"
                type="text"
                maxLength="35"
                id="ContentPlaceHolder1_txtPrivateNameTag"
                spellCheck="false"
                className="form-control"
                value={fields.tag}
                onChange={e => {
                  setFields({ ...fields, tag: e.target.value });
                }}
              />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatetagtip">Private Name Tags (up to 35 characters) can be used for easy identification of addresses</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="nameTag">&nbsp;Private Note :</label>
            <div className="input-group input-group-sm col-sm-12">
              <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
                rows="6"
                cols="20"
                id="ContentPlaceHolder1_txtPrivateNoteArea"
                spellCheck="false"
                className="form-control"
                value={fields.note}
                onChange={e => {
                  setFields({ ...fields, note: e.target.value });
                }}
              />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip"></span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleNotesAddress} />
      </Modal.Footer>
    </Modal>
  )
}

function ShowView({ data = {}, user, show, onHide }) {
  const [fields, setFields] = useState({
    note_id: data.note_id || '',
    note: data.note || '',
    tag: data.tag || ''
  });
  const [editResult, setEditResult] = useState({});
  const adsEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const handleEditTxnNotes = () => {
    if (!fields.tag) {
      message.warning("Tag Cannot be empty!")
      return
    };
    adsEditRequest.run(fields).then(res => {
      if (res.code == 0) {
        setEditResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    });
    // onHide();
  };
  const showError = typeof adsEditRequest.data?.code !== 'undefined' && adsEditRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>My Address Private Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Address Tag updated successfully.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {adsEditRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Address">&nbsp;Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtAddress"
                type="text"
                id="ContentPlaceHolder1_txtAddress"
                className="form-control"
                value={data.address}
                readonly="" />
              <span id="ContentPlaceHolder1_rfvAddress" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }}><br /> *Invalid address</span>
              <p></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="nameTag">&nbsp;Name Tag :</label>
            <div className="input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtPrivateNameTag"
                type="text"
                maxLength="35"
                id="ContentPlaceHolder1_txtPrivateNameTag"
                spellCheck="false"
                value={fields.tag}
                onChange={e => {
                  setFields({ ...fields, tag: e.target.value });
                }}
                className="form-control" />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatetagtip">Private Name Tags (up to 35 characters) can be used for easy identification of addresses</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="nameTag">&nbsp;Private Note :</label>
            <div className="input-group input-group-sm col-sm-12">
              <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea" rows="6" cols="20" id="ContentPlaceHolder1_txtPrivateNoteArea" spellCheck="false" className="form-control"
                value={fields.note}
                onChange={e => {
                  setFields({ ...fields, note: e.target.value });
                }} />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip"></span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Save Change" className="btn btn-sm btn-primary btn-xs" onClick={handleEditTxnNotes} />
      </Modal.Footer>
    </Modal>
  )

}
function Remove({ data = {}, user, show, onHide }) {
  const [delResult, setDelResult] = useState({});
  const adsNoteDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleDelTxnNotes = () => {
    adsNoteDeleteRequest.run({ note_id: data.note_id }).then(res => {
      if (res.code == 0) {
        setDelResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    })
  };
  const showError = typeof adsNoteDeleteRequest.data?.code !== 'undefined' && adsNoteDeleteRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {delResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully removed '{data.address}'
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {adsNoteDeleteRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <p>Are you sure you wish to remove the address tag?<br />
            <span className="text-secondary font-weight-normal" id="removeaddress">{data.address}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Close
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleDelTxnNotes} />
      </Modal.Footer>
    </Modal>
  )

}

function MyNotesAddressList({ user, update, filter }) {
  const [showView, setShowView] = useState(false);
  const [removeView, setRemoveView] = useState(false);

  const [curAdsNote, setCurAdsNote] = useState();

  let state = {
    start: '1',
    length: '50',
    filter: filter || ''
  }

  const adsNoteListRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    adsNoteListRequest.run(state);
  }, [update, filter]);

  const data = adsNoteListRequest.data?.address_note_list || [];
  const counts = adsNoteListRequest.data?.counts || 0;
  const limit_counts = adsNoteListRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.length);

  const handleCreateHide = () => {
    adsNoteListRequest.run(state);
    setShowView(false);
  };
  const handleCreateHideRemove = () => {
    adsNoteListRequest.run(state);
    setRemoveView(false);
  };
  const isFavoriteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/markFavorite',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const changeFavorite = (note_id, is_favorite) => {
    isFavoriteRequest.run({ note_id, is_favorite: is_favorite == 0 ? 1 : 0 }).then(res => {
      if (res.code !== 0) {
        message.error(res.enMsg)
      } else {
        message.success(res.enMsg)
      }
      adsNoteListRequest.run(state);
    })
  }
  return (
    <div className="card-body">
      <span id="spanResult"></span>
      <div className="alert alert-info" id="success-alert" style={{ display: 'none' }}>
        <button type="button" className="close" data-dismiss="alert">
          x
        </button>
        <span id="actionResult"></span>
      </div>
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
          <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{ display: 'none', marginTop: '4px' }}>
            &nbsp;
          </i>
          &nbsp;{counts} used (Out of {limit_counts} max quota)
        </p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure id="SVGdataReport1" className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th
                    width="1"
                    scope="col"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Mark your favorite address to the top of the list"
                  >
                    <i className="fal fa-question-circle text-secondary mr-1"></i>
                  </th>
                  <th scope="col">Action</th>
                  <th scope="col">Name Tag</th>
                  <th scope="col">Address Note</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top">
                          <i onClick={() => changeFavorite(item.note_id, item.is_favorite)}>
                            {item.is_favorite == 1 ? <HeartFilled /> : <HeartOutlined />}
                          </i>
                        </td>
                        <td className="align-top">
                          <a data-toggle="modal"
                            data-target="#responsive"
                            className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                            rel="tooltip"
                            title=""
                            onClick={() => {
                              setShowView(!showView);
                              setCurAdsNote(item)
                            }}
                            data-original-title="View/Update Private Tag/Note">Edit</a>
                          <a data-toggle="modal"
                            data-target="#myModaldelete"
                            className="btn btn-outline-secondary btn-xss html-link-custom"
                            rel="tooltip"
                            title=""
                            onClick={() => {
                              setRemoveView(!removeView);
                              setCurAdsNote(item)
                            }}
                            data-original-title="Remove Private Tag/Note">Remove</a>
                        </td>
                        <td className="align-top">
                          <span className="u-label u-label--value u-label--secondary text-dark" id={item.address}>{item.tag}</span>
                        </td>
                        <td className="align-top">
                          <span className="d-block">
                            <a href={`/address/${item.address}`} className="d-flex">
                              <span className="text-primary" id={item.address}>{item.address}</span>
                              <Paragraph copyable={{ text: item.address }} />
                            </a>
                          </span>
                          <span className="d-block small text-break" id={item.note_id}>{item.note}</span>
                        </td>
                        <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                      </tr>
                    )
                  })
                ) : adsNoteListRequest.loading ? (
                  <tr />
                ) : (
                  <tr>
                    <td colSpan="12" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">There are no private tags found.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      {showView && <ShowView show={showView} data={curAdsNote} user={user} onHide={handleCreateHide} />}
      <Remove show={removeView} data={curAdsNote} user={user} onHide={handleCreateHideRemove} />
    </div>
  )
}

export default function MyNotesAddress() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isFlag, setIsFlag] = useState(false);

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_address" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Address Private Notes</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a
                      href="#"
                      id="ContentPlaceHolder1_addnew"
                      data-toggle="modal"
                      data-target="#responsive"
                      rel="tooltip"
                      title=""
                      className="btn btn-xss btn-primary rounded"
                      onClick={() => {
                        setShow(!show);
                      }}
                      data-original-title="Add a new address private note"
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  {searchStr && <div className="btn-group order-2 order-md-1 ml-2 mr-md-2" role="group">
                    <span className='btn btn-xss btn-soft-secondary ml-auto'>Filtered by
                      <span className='hash-tag hash-tag text-truncate font-weight-bold ml-2'>{searchStr}</span>
                    </span>
                    <a className="btn btn-sm btn-icon btn-soft-secondary" href="javascript:;"
                      onClick={() => setSearchStr('')}
                    >
                      <i className='fa fa-times btn-icon__inner' />
                    </a>
                  </div>}

                  <div className="position-relative order-1 order-md-2">
                    <a
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      onClick={() => setIsFlag(!isFlag)}
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    {isFlag && <div className="searchBox">
                      <Search
                        placeholder="Search Address Private"
                        allowClear
                        enterButton="Find"
                        size="large"
                        onSearch={(value) => {
                          setSearchStr(value)
                          setIsFlag(!isFlag)
                        }}
                      />
                    </div>}
                  </div>
                </div>
              </div>
              <MyNotesAddressList user={user} update={show} filter={searchStr} />
            </div>
          </div>
          <Augment user={user} show={show} onHide={() => setShow(!show)} />
        </div>
      </div>
    </main>
  );
}
