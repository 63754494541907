import { useState, useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import qs from 'qs';

export default function MessageStatus() {
    const location = useLocation();
    const [emailVal, setEmailVal] = useState('');
    const [result, setResult] = useState({});

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const activedResult = JSON.parse(sessionStorage.getItem('activedResult'))
        setResult(activedResult)
        console.log(query, 'queryqueryquery');
        setEmailVal(query.email)
    }, [])

    const goBack = () => {
        // window.location.href = `/promptActive?email=${emailVal}`;
        window.location.href = `/login`;
    }

    const goLogin = () => {
        window.location.href = `/login`;
    }

    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                {/* 邮件激活成功页面 */}
                {result.code == 0 && (<div className="w-lg-50 mx-md-auto">
                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                        <CheckCircleOutlined style={{ fontSize: '42px', color: 'green' }} />
                        <div className='font-weight-bold mb-4 mt-2'>Activation succeeded </div>
                        <div className='d-block mb-6'>Your account is already verified.You may proceed to sign in with your Username and Password</div>
                        <Button type='primary' onClick={goLogin}>Sign In Now</Button>
                    </div>
                </div>)}
                {/* 邮件链接失败页面 */}
                {result.code != 0 && (<div className="w-lg-50 mx-md-auto">
                    <div className='mb-6'>
                        <div className='font-weight-bold mb-2'>{result.enMsg}</div>
                        <div className='d-block'>Please don't activate repeatedly. Perhaps you have successfully activated, please Sign in to view.
                            If activation is not successful, please resend email.</div>
                    </div>
                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                        <Button type='primary' onClick={goBack}>Sign In Now</Button>
                    </div>
                </div>)}
            </div>
        </main>
    )
}