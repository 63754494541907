import { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useRequest } from 'ahooks';
import { message, Button, Form, Checkbox } from 'antd'
import moment from 'moment';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';

function Augment({ user, show, onHide }) {
  const [fields, setFields] = useState({
    token: '',
    note: ''
  });
  const [addResult, setAddResult] = useState({});
  const ignoreCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/tokenIgnore/ignoreCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleAddApiKey = () => {
    ignoreCreateRequest.run(fields).then(res => {
      if (res.code == 0) {
        setAddResult(res)
        setFields({
          token: '',
          note: ''
        })
        setTimeout(() => {
          onHide();
          setAddResult({})
        }, 1500);
      }
    });
  };

  const showError = typeof ignoreCreateRequest.data?.code !== 'undefined' && ignoreCreateRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Create a new Token Ignore entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully Created New Token Ignore.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {ignoreCreateRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-3 mt-3" htmlFor="AppName">&nbsp;Token Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtTokenContact"
                type="text"
                maxLength="42"
                id="ContentPlaceHolder1_txtTokenContact"
                className="form-control"
                required=""
                data-msg="Please enter a valid Token Contract address"
                data-error-class="u-has-error"
                data-success-class="u-has-success"
                value={fields.token}
                onChange={e => {
                  setFields({ ...fields, token: e.target.value });
                }}
                placeholder="Enter a Token Contract Address" />
              <span id="ContentPlaceHolder1_RegularExpressionValidator1" style={{ color: 'red', fontSize: 'Small', display: 'none' }}><br /> *Invalid Address</span>
              <p>
              </p>
            </div>
            <p className="mt-3 mb-1 ml-3">Note (optional)</p>
            <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
              rows="3"
              cols="20"
              id="ContentPlaceHolder1_txtPrivateNoteArea"
              spellCheck="false"
              className="ml-3 form-control"
              value={fields.note}
              onChange={e => {
                setFields({ ...fields, note: e.target.value });
              }}
            ></textarea>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip">A note of up to 155 characters can be attached to this entry and be used to explain why this token was entered into the ignore list</span>
            </div>
            <input name="ctl00$ContentPlaceHolder1$txtType"
              type="text"
              id="ContentPlaceHolder1_txtType"
              style={{ display: 'none' }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddApiKey} />
      </Modal.Footer>
    </Modal>
  )
}

function ShowView({ data = {}, user, show, onHide }) {
  const [fields, setFields] = useState({
    ignore_id: data.ignore_id || '',
    note: data.note || '',
  });
  const [editResult, setEditResult] = useState({});
  const ignoreEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/tokenIgnore/ignoreEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const editConfirm = () => {
    ignoreEditRequest.run(fields).then(res => {
      if (res.code == 0) {
        setEditResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    });
  };
  const showError = typeof ignoreEditRequest.data?.code !== 'undefined' && ignoreEditRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title> Update Token Ignore entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully updated Token Ignore entry
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {ignoreEditRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-3 mt-3" for="AppName">&nbsp;Token Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtTokenContact"
                type="text"
                id="ContentPlaceHolder1_txtTokenContact"
                className="form-control"
                required=""
                value={data.token}
                readonly="" />
              <span id="ContentPlaceHolder1_RegularExpressionValidator1" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }}><br /> *Invalid Address</span>
              <p>
              </p>
            </div>
            <p className="mt-3 mb-1 ml-3">Note (optional)</p>
            <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
              rows="3"
              cols="20"
              id="ContentPlaceHolder1_txtPrivateNoteArea"
              spellCheck="false" className="ml-3 form-control" value={fields.note}
              onChange={e => {
                setFields({ ...fields, note: e.target.value });
              }} />
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip">A note of up to 155 characters can be attached to this entry and be used to explain why this token was entered into the ignore list</span>
            </div>
            <input name="ctl00$ContentPlaceHolder1$txtType" type="text" value="0" id="ContentPlaceHolder1_txtType" style={{ display: 'none' }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={editConfirm} />
      </Modal.Footer>
    </Modal>
  )
}

function Remove({ data = {}, user, show, onHide }) {
  const [delResult, setDelResult] = useState({});
  const ignoreDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/tokenIgnore/ignoreDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleDeleted = () => {
    ignoreDeleteRequest.run({ ignore_id: data.ignore_id }).then(res => {
      if (res.code == 0) {
        setDelResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    })
  };
  const showError = typeof ignoreDeleteRequest.data?.code !== 'undefined' && ignoreDeleteRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {delResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully removed Token Ignore Entry '{data.token}'
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {ignoreDeleteRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <p>Are you sure you wish to remove the token address? <br />
            <span className="text-secondary font-weight-normal" id="removetxhash">{data.token}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Close
        </button>
        <input type="button" value="Delete" className="btn btn-sm btn-primary btn-xs" onClick={handleDeleted} />
      </Modal.Footer>
    </Modal>
  )
}


function MyTokenignoreList({ user, update }) {

  const [showView, setShowView] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [isChecked, setIsChecked] = useState(0);

  const [curTokenIgnore, setCurTokenIgnore] = useState();

  const [state, setState] = useState({
    body: {
      start: '1',
      length: '50',
      // field: 'key_name',
      // value: '',
    },
  });

  const listTokenRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/tokenIgnore/ignoreList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    listTokenRequest.run(state.body);
  }, [update]);

  const data = listTokenRequest.data?.token_ignore_list || [];
  const counts = listTokenRequest.data?.counts || 0;
  const limit_counts = listTokenRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const ignore_poor_reputation = listTokenRequest.data?.ignore_poor_reputation
  useEffect(() => {
    setIsChecked(ignore_poor_reputation)
  }, [ignore_poor_reputation])

  const handleCreateHide = () => {
    listTokenRequest.run(state.body);
    setShowView(false);
  };
  const handleCreateHideRemove = () => {
    listTokenRequest.run(state.body);
    setRemoveView(false);
  };

  const hideToken = (e) => {
    setIsChecked(Number(e.target.checked))
  }

  const ignorePoorReputation = useRequest(
    body => ({
      url: '/blockBrowser/user/tokenIgnore/ignorePoorReputation',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  )

  const onFinish = () => {
    ignorePoorReputation.run({ is_ignore: isChecked }).then(res => {
      if (res.code === 0) message.success(res.enMsg)
      else message.error(res.enMsg)
    })
  };

  return (
    <div className="card-body">
      <div className="alert alert-info" id="success-alert" style={{ display: 'none' }}>
        <button type="button" className="close" data-dismiss="alert">
          x
        </button>
        <span id="actionResult"></span>
      </div>
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">&nbsp;{counts} used (Out of {limit_counts} max quota)</p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.body.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure id="SVGdataReport1" className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Token Contract Address</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.isArray(data) && data.length !== 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-top">
                            <a data-toggle="modal"
                              data-target="#myModal"
                              className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                              rel="tooltip"
                              title=""
                              onClick={() => {
                                console.log(1111111)
                                setShowView(!showView);
                                setCurTokenIgnore(item)
                              }}
                              data-original-title="Update Token ignore entry">Edit</a>
                            <a data-toggle="modal"
                              data-target="#myModaldelete"
                              className="btn btn-outline-secondary btn-xss html-link-custom"
                              rel="tooltip"
                              title=""
                              onClick={() => {
                                setRemoveView(!removeView);
                                setCurTokenIgnore(item)
                              }}
                              data-original-title="Remove Token Ignore entry">Remove</a>
                          </td>
                          <td className="align-top">
                            <a href={`/token/${item.token}`}>{item.token}</a>
                            (<img src="/images/main/empty-token.png" width="13px" /> {item.token_name})
                            <span className="d-block small text-break" id="n_0xe9e7cea3dedca5984780bafc599bd69add087d56">{item.note}</span>
                          </td>
                          <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                        </tr>
                      )
                    })
                  ) : listTokenRequest.loading ? (
                    <tr />
                  ) : (
                    <tr>
                      <td className="align-top" colSpan="4">
                        <center>
                          <div className="alert alert-secondary mt-4" role="alert">
                            <i className="fal fa-info-circle"></i>
                            <span className="text-dark">You have yet to create a Token Ignore entry.</span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className='h5 text-dark mb-0'>Ignore Tokens with Poor Reputation</h3>
        </div>
        <div className="card-body">
          <p className='mb-0'>Hide all transactions of tokens that have been flagged by CMPScan as Suspicious, Unsafe, Spam or Brand Infringement from being displayed in the Address page.</p>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <div className="custom-control custom-checkbox mr-sm-3 mb-2 mb-sm-0">
            <Checkbox checked={isChecked} onChange={(e) => hideToken(e)}>Hide tokens</Checkbox>
          </div>
          <div className="text-nowrap">
            <Button type="primary" onClick={() => onFinish()}>Save Changes</Button>
          </div>
        </div>
      </div>
      {showView && <ShowView show={showView} data={curTokenIgnore} user={user} onHide={handleCreateHide} />}
      <Remove show={removeView} data={curTokenIgnore} user={user} onHide={handleCreateHideRemove} />
    </div>
  )
}

export default function MyTokenIgnore() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mytokenignore" user={user} />
          <div className="col-md-9">
            <div className="card mb-4">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Token Ignore List</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <div className="position-relative order-md-2">
                      <a
                        href="#"
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#myModal"
                        title="Create a new Ignore Token entry"
                        className="btn btn-xss btn-primary rounded"
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <MyTokenignoreList user={user} update={show} />
            </div>
          </div>
          <Augment user={user} show={show} onHide={() => setShow(!show)} />
        </div>
      </div>
    </main>
  );
}
