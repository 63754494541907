import { useState, useContext } from 'react';
import { useRequest } from 'ahooks';
import { Metamask } from 'ethpay.core';
import { useParams } from 'react-router-dom';
import { Button, Form, Input, message, Typography } from 'antd';
import UserContext from '../../UserContext';

const { Paragraph } = Typography;
let web3 = undefined;
window.Metamask = Metamask;
const ethereum = window.ethereum;
export default function VerifyAddress() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const { ads } = useParams();
    console.log(ads, 'adsadsads');
    const [form] = Form.useForm();
    const [createData, setCreateData] = useState({});
    const [currentAccount, setCurrentAccount] = useState();
    const [address, setAddress] = useState();
    const [showSign, setShowSign] = useState(false);
    const [open, setOpen] = useState(false);
    const [last, setLast] = useState(false);

    const [createResult, setCreateResult] = useState({});
    const [verifyResult, setVerifyResult] = useState({});

    const adsCreateRequest = useRequest(
        body => ({
            url: '/blockBrowser/user/verifiedAddress/addressCreate',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );
    const onFinish = (values) => {
        setAddress(values)
        // setOpen(true);
        adsCreateRequest.run({ ...values }).then(res => {
            if (res.code == 0) {
                setCreateResult(res)
                setCreateData(res.data)
                setOpen(true);
            } else {
                message.error(res.enMsg)
            }
        })
    };
    // 验证签名
    const verifyRequest = useRequest(
        body => ({
            url: '/blockBrowser/user/verifiedAddress/verify',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinishVerify = (values) => {
        verifyRequest.run({ ...values }).then(res => {
            if (res.code == 0) {
                message.success('Successfully verify.')
                setVerifyResult(res)
                setShowSign(false)
                setLast(true)
            } else {
                message.error(res.enMsg)
            }
        })
    }
    // 验证签名结果
    const showError = typeof verifyRequest.data?.code !== 'undefined' && verifyRequest.data?.code !== 0;
    const onReset = () => { form.resetFields() }

    const handleConnect = async e => {
        console.log('222');
        e.preventDefault();
        if (!web3) {
            try {
                const newAccounts = await ethereum.request({ method: 'eth_requestAccounts' });
                setCurrentAccount(Array.isArray(newAccounts) ? newAccounts[0] : undefined);
            } catch (error) {
                message.error(error.message)
                return
            }
        }
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x3e900' }], // 256256 
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x3e900', // 256256 0x3e900 0x3E900
                                chainName: 'CAD-Mainnet',
                                nativeCurrency: {
                                    name: 'CAD',
                                    symbol: 'CAD',
                                    decimals: 18
                                },
                                rpcUrls: ['https://mainnet.block.caduceus.foundation'],
                                blockExplorerUrls: ['https://mainnet.scan.caduceus.foundation']
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                    message.error(switchError.message)
                    return
                }
            } else {
                message.error(switchError.message)
                return
            }
        }
    };

    const handleSign = async e => {
        console.log('11111');
        e.preventDefault();
        const provider = await Metamask.getProvider();
        web3 = new window.Web3(provider);

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x3e900' }], // 256256 
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x3e900', // 256256 0x3e900 0x3E900
                                chainName: 'CAD-Mainnet',
                                nativeCurrency: {
                                    name: 'CAD',
                                    symbol: 'CAD',
                                    decimals: 18
                                },
                                rpcUrls: ['https://mainnet.block.caduceus.foundation'],
                                blockExplorerUrls: ['https://mainnet.scan.caduceus.foundation']
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                    message.error(switchError.message)
                    return
                }
            } else {
                message.error(switchError.message)
                return
            }
        }

        try {
            // let msg = web3.utils.utf8ToHex(createData.message);
            web3.eth.personal.sign(createData.message, currentAccount).then(e => {
                if (e) {
                    setShowSign(false)
                    let params = {
                        contract_address: address.contract_address,
                        contract_creator: createData.contract_creator,
                        message: createData.message,
                        signature: e
                    }

                    verifyRequest.run({ ...params }).then(res => {
                        if (res.code !== 0) {
                            message.error(res.enMsg)
                        } else {
                            message.success(res.enMsg)
                            setOpen(false)
                            setLast(true)
                        }
                    })
                }
            }).catch(error => {
                message.error('User denied message signature.')
                return
            })
        } catch (error) {
            message.error(error.message)
            return
        }
    };

    return (
        <main role="main">
            <div className="container space-1">
                {/* <div className="row">
                    {verifyResult.code === 0 ? (
                        <div className="alert alert-success">
                            <strong>Status:</strong>Successfully verify.
                        </div>
                    ) : ''}
                </div> */}

                {/* 第一步 */}
                {(!open && !showSign && !last) && (<div className="w-md-75 w-lg-50 mx-md-auto">
                    <div className="text-center mb-4">
                        <h2 className='h3 mb-1'>Verify Address Ownership</h2>
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <Form layout="vertical" onFinish={(values) => onFinish(values)} form={form}>
                                <Form.Item name="contract_address" label="contract address"
                                    rules={[{ required: true, message: 'Contract address is required!' }]}
                                    initialValue={ads != 'add' ? ads : ''}
                                >
                                    <Input placeholder="(0x...)" />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="button" onClick={onReset} className="mr-4">Reset</Button>
                                    <Button type="primary" className="mr-4" onClick={() => { window.history.back() }}>BACK</Button>
                                    <Button type="primary" htmlType="submit">NEXT</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>)}
                {/* 第二步 */}
                {open && <div className="w-md-75 w-lg-50 mx-md-auto">
                    <div className="text-center mb-4">
                        <h2 className='h3 mb-1'>Verify Address Ownership</h2>
                        {/* <p>Find out more about <a href="">Verify Address Ownership</a></p> */}
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <div id="ContentPlaceHolder1_verifyaddress2div">
                                <div className="row mb-4">Copy the message below and sign it using the CAD sign message provider of your choice.</div>
                                <div className="js-form-message form-group">
                                    <div id="ContentPlaceHolder1_ContractCreator" className="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            name='ctl00$ContentPlaceHolder1$ContractOwnerCreator'
                                            className="custom-control-input"
                                            id="ContentPlaceHolder1_RadioButton0"
                                            checked="checked"
                                        />
                                        <label className='custom-control-label' for="ContentPlaceHolder1_RadioButton0">
                                            {/* Contract Creator: 0xbe4b4d07e9559951e8ae4f53f7c53adc44065256 */}
                                            Contract Creator: {createData.contract_creator}
                                        </label>
                                    </div>
                                </div>
                                <div className="js-form-message form-group">
                                    <label for="signedMessage" className='d-flex justify-content-between align-items-center'>
                                        <span>Message To Sign</span>
                                        <Paragraph copyable={{ text: createData.message }} />
                                    </label>
                                    <textarea
                                        name="ctl00$ContentPlaceHolder1$txtRawMessage"
                                        id="ContentPlaceHolder1_txtRawMessage"
                                        cols="20" rows="3" disabled="disabled"
                                        className='aspNetDisabled form-control form-control-sm'
                                        value={createData.message}
                                    />
                                </div>
                                <div className="row">
                                    <p>Check our article on "<a href="javascript:void(0)">How to Sign Message?</a>" if you have not done before.</p>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="justify-content-between align-items-center" align="center">
                                        <a href="javascript:void(0)" className="btn btn-sm btn-primary mr-4" onClick={() => setOpen(false)}>
                                            BACK
                                        </a>
                                        <input
                                            type="submit"
                                            name='ctl00$ContentPlaceHolder1$btnSubmit2'
                                            className="btn btn-sm btn-primary"
                                            value="Sign Message Manually"
                                            id="ContentPlaceHolder1_btnSubmit2"
                                            onClick={() => {
                                                setShowSign(true)
                                                setOpen(false);
                                            }}
                                        />
                                        <span className='text-secondary px-2'>OR</span>
                                        <a href="#" className="btn btn-sm btn-primary " onClick={(e) => { currentAccount ? handleSign(e) : handleConnect(e) }}>
                                            {!currentAccount ? 'Connect to Web3' : 'Sign with Web3'}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* 第三步———验证 */}
                {showSign && (<div className="w-md-75 w-lg-50 mx-md-auto">
                    <div className="text-center mb-4">
                        <h2 className='h3 mb-1'>Verify Address Ownership</h2>
                        {/* <p>Find out more about <a href="">Verify Address Ownership</a></p> */}
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <Form layout="vertical" onFinish={onFinishVerify}>
                                <Form.Item name="contract_creator" label="Contract Owner/Creator Address"
                                    rules={[{ required: true }]}
                                    initialValue={createData.contract_creator}
                                // initialValue="7777777777"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name="contract_address" label="Contract Owner/Creator Address"
                                    hidden
                                    initialValue={address.contract_address}
                                >
                                    <Input readOnly />
                                </Form.Item>
                                <Form.Item name="message" label="Signed Message"
                                    rules={[{ required: true, message: 'Signed Message is required!' }]}
                                    initialValue={createData.message}
                                // initialValue="888888888888"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item name="signature" label="Message Signature Hash"
                                    rules={[{ required: true, message: 'signature is required!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="button" onClick={onReset} className="mr-4">Reset</Button>
                                    <Button type="primary" htmlType="submit" className='mr-4' onClick={() => { setShowSign(false); setOpen(true) }}>BACK</Button>
                                    <Button type="primary" htmlType="submit">Verify Ownership</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>)}
                {/* 第四步 */}
                {last && (<div className="w-md-75 w-lg-50 mx-md-auto">
                    <div className="text-center mb-4">
                        <h2 className='h3 mb-1'>Verify Address Ownership</h2>
                        {/* <p>Find out more about <a href="">Verify Address Ownership</a></p> */}
                    </div>
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="alert alert-success mb-4" role="alert">
                                <i className='fas fa-check-circle text-success mr-2' />
                                Congratulations, the address ownership for [{address.contract_address}] is verified. You may now submit the request to:
                            </div>
                            <div id="ContentPlaceHolder1_verifyaddress4div">
                                <div className="py-1">1.
                                    <a href={`/tokenupdate/${address.contract_address}/verified`}>&nbsp;Update Token Information</a>
                                </div>
                                <div className="py-1">2.
                                    <a href={`/contactus/${address.contract_address}/verified`}>&nbsp;Add Name Tag</a>
                                </div>
                                <div className="py-1">3.
                                    <a href={`/contactus/${address.contract_address}/verified`}>&nbsp;Add Project Label (s)</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

            </div>
        </main>
    )
}

