import { useState } from 'react';
import { useRequest } from 'ahooks';
import { Form, Button, Checkbox, Input, Row, } from 'antd';

import VerifyCode from '../../components/VerifyCode';

import './index.css'

export default function Register({ flag }) {
  const [form] = Form.useForm();
  const registerRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/login/signUp',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const verfiyCodeRequest = useRequest(
    body => ({
      url: '/blockBrowser/common/verifyCode',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  const [captchaId, setCaptchaId] = useState('');

  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    srcflag: false,
    refurbish: false,
  })

  const handleUpdateVerifyCode = e => {
    setCaptchaId(e.captcha_id)
    if (e.verify_code) {
      setErrors({ ...errors, verify_code: undefined });
    }
  };

  const handleCfmPwd = (rules, value, callback) => {
    console.log(rules, value, callback, 'pppp');
  }

  const onFinish = (value) => {
    setState({
      srcflag: true,
      refurbish: true,
    })
    let obj = { ...value, captcha_id: captchaId }
    console.log(obj, 'obj');

    registerRequest.run({ ...obj }).then(res => {
      if (res.code === 0) {
        // window.location.href = '/login';
        window.sessionStorage.setItem('key', res.data.key)
        window.location.href = `/promptActive?email=${value.email}`;
      } else if (res.code !== 0) {
        verfiyCodeRequest.run()
      }
    });
  }

  const showError = typeof registerRequest.data?.code !== 'undefined' && registerRequest.data?.code !== 0;

  return (
    <main className="bg-light content-height" role="main">
      <div className="container space-2">
        <div className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
          <div className="mb-7">
            <h1 className="h3 text-primary font-weight-normal mb-2">
              Register a <span className="font-weight-semi-bold">New</span> Account
            </h1>
            <p>Fill out the form to get started.</p>
          </div>
          <div className="alert alert-danger" style={{ display: showError ? 'block' : 'none' }}>
            {registerRequest.data?.enMsg}
          </div>
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <div className='js-form-message form-group'>
              <Form.Item
                name="user_name"
                label="Username"
                rules={[{ required: true, message: 'Please enter a username that includes at least 5 to 30 characters in uppercase, lowercase, numeric.', pattern: /^[\w\-.]{5,30}$/ }]}
              >
                <Input placeholder="Username has to be from 5 to 30 characters in length, only alphanumeric characters allowed." />
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[
                  {
                    required: true, type: 'email', message: 'Please enter a valid email with 0 to 64 characters.',
                    pattern: /^(?=\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,64}$/
                  }
                ]}
              >
                <Input placeholder="A confirmation code will be sent to this address" />
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item
                name="password"
                label="Password"
                // rules={[
                //   { required: true, },
                //   {
                //     validator: (rules, value, callback) => { handleCfmPwd(rules, value, callback) }
                //   },
                // ]}
                rules={[
                  {
                    required: true, pattern: /((?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])|(?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[A-Z])(?=.*\d)(?=.*[\W_])|(?=.*[a-z])(?=.*\d)(?=.*[\W_]))[a-zA-Z\d\W_]{8,20}$/, message: 'Please enter a password that includes at least 8 to 20 characters in three types: uppercase, lowercase, numeric, and special.',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="******" />
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, message: 'Please confirm your password!', },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="******" />
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item name="verify_code" label="Captcha" rules={[{ required: true, message: 'Please input the captcha you got!' }]}>
                <Row style={{ display: 'inherit', width: '100%' }}>
                  <VerifyCode value={form.verify_code} onChange={handleUpdateVerifyCode} src={verfiyCodeRequest.data?.image_data} handleRefresh={verfiyCodeRequest.data?.image_data} refurbish={state.refurbish} srcflag={state.srcflag} />
                </Row>
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item
                name="terms"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                  },
                ]}
              >
                <Checkbox>
                  I agree to the <a href="" lassName="link-muted" target="_blank">Terms and Conditions</a>
                </Checkbox>
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item
                name="newsletter"
                valuePropName="checked"
                rules={[]}
              >
                <Checkbox>
                  I agree to receive the CMPScan newsletter and understand that I can unsubscribe at any time.
                </Checkbox>
              </Form.Item>
            </div>
            <div className='js-form-message form-group'>
              <Form.Item className='end-item'>
                <Button type="primary" htmlType="submit" className="mb-2" style={{ width: '70%' }} disabled={registerRequest.loading}>Create an Account</Button>
                <p><span className="text-muted">Already have an account?</span> <a className="text-nowrap" href="/login">Click to Sign In</a></p>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
}
