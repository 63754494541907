import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';

export default function VerifyCode({ value, onChange, src, srcflag, refurbish }) {
  const verfiyCodeRequest = useRequest(
    body => ({
      url: '/blockBrowser/common/verifyCode',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );
  const [state, setState] = useState({
    flag: false,
  })

  useEffect(() => {
    verfiyCodeRequest.run();
  }, []);

  // srcflag=true  state.flag=fasle  refurbish=true   错误可刷新
  // srcflag=true  state.flag=true   refurbish=true    点击可刷新
  const handleRefresh1 = () => {

    verfiyCodeRequest.run();
    console.log("11")
    if (srcflag === true) {
      setState({
        flag: !state.flag,
      })
    }
  };

  // console.log(srcflag,'srcflag')
  // console.log(state.flag,'state.flag')
  // console.log(refurbish,'refurbish')
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        maxLength="100"
        className="form-control form-control-sm"
        type="text"
        placeholder="captcha"
        data-msg="Please enter a valid captcha."
        value={value}
        style={{ width: '80%' }}
        onChange={e => {
          onChange({
            captcha_id: verfiyCodeRequest.data?.captcha_id,
            verify_code: e.target.value,
          });
        }}
      />
      {verfiyCodeRequest.data?.image_data ? (
        // <img src={src === undefined ? `${verfiyCodeRequest.data?.image_data}` : `${src}`} onClick={handleRefresh1} style={{position: 'absolute', top: '1px', right: '1px', height: '39px', borderRadius: '4px'}} />
        <img src={src === undefined ? `${verfiyCodeRequest.data?.image_data}` : `${refurbish === false ? verfiyCodeRequest.data?.image_data : src}`} onClick={handleRefresh1} style={{ position: 'absolute', top: '1px', right: '1px', height: '39px', borderRadius: '4px' }} />
      ) : undefined}
    </div>
  );
}
