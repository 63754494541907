import { Tooltip, message } from 'antd'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Copy from '../../components/Copy';

export default function ProfileSummary({ data, tokensDetail, user }) {
  console.log(data,'datadata');
  const [toggleTools, setToggleTools] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const tokenAddress = data.token_address
  const tokenDecimals = data.decimals
  const tokenSymbol = tokensDetail.token_symbol
  const tokenImage = window.location.origin + '/images/main/empty-token.png'

  const addTokens = async () => {
    const host = window.location.host;
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const isMainnetShow = (host === 'mainnet.scan.caduceus.foundation' || host === 'cmpscan.io') && Number(chainId) !== 256256
    const isGalaxyShow = host !== 'mainnet.scan.caduceus.foundation' && host !== 'cmpscan.io' && Number(chainId) !== 512512
    if (isMainnetShow || isGalaxyShow) {
      message.warning(`Only support chain: ${host === 'mainnet.scan.caduceus.foundation' || host === 'cmpscan.io' ? 256256 : 512512}`)
      return
    }

    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        message.success('Token added Successfully!')
      } else {
        message.error('Failed to add the token')
      }
    } catch (error) {
      console.log(error);
      if (error.code !== 4001) message.error(error.message)

    }
  }

  return (
    <div className="card h-100">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex d-none">
          <h2 className="card-header-title mr-1 mb-0 d-flex mb-1">Profile Summary</h2>
        </div>
        <div className="d-flex d-none">
          {/* <div className="ml-auto">
            <a className="btn btn-xs btn-icon btn-soft-primary mr-1" href="#" data-toggle="tooltip" data-title="Reputation NEUTRAL" data-original-title="" title="">
              <span className="fas fa-meh btn-icon__inner"></span>
            </a>
          </div>
          <div className="ml-auto">
            <a
              className="btn btn-xs btn-icon btn-soft-info ml-1 mr-1"
              href="#"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              data-title="Blue Check Mark - Click for more info"
              data-original-title=""
              title=""
            >
              <i className="fa fa-check btn-icon__inner"></i>
            </a>
          </div> */}
          <div className="position-relative">
            <a
              href="#"
              data-unfold-animation-out="fadeOut"
              aria-expanded="false"
              data-unfold-type="css-animation"
              data-unfold-target="#dropdownTools"
              data-unfold-animation-in="slideInUp"
              className="btn btn-xs btn-icon btn-soft-secondary"
              data-unfold-event="click"
              data-unfold-delay="300"
              aria-haspopup="true"
              data-unfold-hide-on-scroll="false"
              role="button"
              aria-controls="dropdownTools"
              data-unfold-duration="300"
              onClick={e => {
                setToggleTools(!toggleTools);
              }}
            >
              <i className="fa fa-ellipsis-v btn-icon__inner"></i>
            </a>
            <div
              className={`dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation ${toggleTools ? 'slideInUp' : 'u-unfold--hidden'}`}
              aria-labelledby="dropdownToolsInvoker-1"
              style={{ animationDuration: '300ms', right: 0 }}
            >
              {/* <a className="dropdown-item" href={`/tokencheck-tool?t=${data.token_address}`}>
                <i className="fa fa-fw fa-history mr-1"></i>Check previous token supply
              </a> */}
              <a className="dropdown-item" href="#" onClick={() => addTokens()}>
                <i className="fas fa-fw fa-wallet mr-1"></i>Add Token to Web3 Wallet
              </a>
              <hr className="my-1" />
              {(data.is_source_code_verified && data.is_owner) ? (
                <a className="dropdown-item" href={`/tokenupdate/${data.token_address}/profile`}>
                  <i className="fa fa-fw fa-pencil-alt mr-1"></i>Update Token Info
                </a>
              ) : (<a className="dropdown-item" href="#"
                onClick={e => {
                  setShowModal(true);
                }}>
                <i className="fa fa-fw fa-tags mr-1"></i>Update Token Info
              </a>)}
              <a className="dropdown-item" href={`/contactus/${data.token_address}/profile`}>
                <i className="fa fa-fw fa-flag mr-1"></i>Update Name Tag
              </a>

            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-4 mb-1 mb-md-0">Contract:</div>
          <div className="col-md-8">
            <div className="d-flex clipboard-hover">
              <a className="text-truncate d-block mr-2" href={`/address/${data.token_address}`}>
                {data.token_address}
              </a>
              <Copy text={data.token_address} title="" />
            </div>
          </div>
        </div>

        <hr className="hr-space" />

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Decimals:</div>
            <div className="col-md-8">{data.decimals}</div>
          </div>
          <hr className="hr-space" />
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Official Site:</div>
            <div className="col-md-8">
              <a href={data.official_website} rel="nofollow" target="_blank">
                {data.official_website} <i className="fa fa-external-link-alt small ml-1"></i>
              </a>
            </div>
          </div>
          <hr className="hr-space" />
        </div>

        <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">Social Profiles:</div>
            <div className="col-md-8">
              <ul className="list-inline mb-0">
                {data.social_profiles.email && <li className="list-inline-item mr-3">
                  <Tooltip title={`Email:${data.social_profiles.email}`}>
                    <span className='fa fa-envelope link-hover-secondary' />
                  </Tooltip>
                </li>}
                {data.social_profiles.reddit && <li className="list-inline-item mr-3">
                  <Tooltip title={`Reddit:${data.social_profiles.reddit}`}>
                    <a target="_blank" href={data.social_profiles.reddit} className='link-hover-secondary'>
                      <span className='fab fa-reddit-square' />
                    </a>
                  </Tooltip>
                </li>}
                {data.social_profiles.twitter && <li className="list-inline-item mr-3">
                  <Tooltip title={`Twitter:${data.social_profiles.twitter}`}>
                    <a target="_blank" href={data.social_profiles.twitter} className='link-hover-secondary'>
                      <span className='fab fa-twitter' />
                    </a>
                  </Tooltip>
                </li>}
                {data.social_profiles.github && <li className="list-inline-item mr-3">
                  <Tooltip title={`Github:${data.social_profiles.github}`}>
                    <a target="_blank" href={data.social_profiles.github} className='link-hover-secondary'>
                      <span className='fab fa-github' />
                    </a>
                  </Tooltip>
                </li>}
                {data.social_profiles.white_paper && <li className="list-inline-item mr-3">
                  <Tooltip title={`Whitepaper:${data.social_profiles.white_paper}`}>
                    <a target="_blank" href={data.social_profiles.white_paper} className='link-hover-secondary'>
                      <span className='fa fa-file-alt' />
                    </a>
                  </Tooltip>
                </li>}
                {data.social_profiles.coin_market_cap && <li className="list-inline-item mr-3">
                  <Tooltip title={`CoinMarketCap:${data.social_profiles.coin_market_cap}`}>
                    <a target="_blank" href={data.social_profiles.coin_market_cap} >
                      <img src="/images/cmc.svg" className="mt-n3px" width="13" />
                    </a>
                  </Tooltip>
                </li>}
                {data.social_profiles.coin_gecko && <li className="list-inline-item mr-3">
                  <Tooltip title={`CoinGecko:${data.social_profiles.coin_gecko}`}>
                    <a target="_blank" href={data.social_profiles.coin_gecko} >
                      <img src="/images/coingecko.svg" className="mt-n3px" width="13" />
                    </a>
                  </Tooltip>
                </li>}
              </ul>
            </div>
            <hr className="hr-space" />
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="p-2">
          <Modal.Title>Update Token Information Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>To submit a token information's update request, please confirm beforehand that you have:</p>
          <ul>
            <li>registered and logged in to your CmpScan account
              {user.user_id && <i className='far fa-check-circle text-success ml-2' />}
            </li>
            <li>verified the contract's source code using our tool <a href={`/verifyContract?a=${data.token_address}`}>here</a> {user.user_id && data.is_source_code_verified == 1 && <i className='far fa-check-circle text-success ml-2' />}</li>
            <li>been successfully verified as the contract address owner</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowModal(false)} className='btn btn-sm btn-secondary btn-xs'>Close</button>
          {user.user_id ? (<button class="btn btn-sm btn-primary btn-xs">
            {data.is_source_code_verified == 0 && <a href={`/verifyContract?a=${data.token_address}`} style={{ color: '#fff' }}>Verify Contract</a>}
            {(data.is_source_code_verified == 1 && data.is_owner == 0) && <a href={`/verifyAddress/${data.token_address}`} style={{ color: '#fff' }}>Verify Address Ownership</a>}
          </button>) :
            (<button class="btn btn-sm btn-primary btn-xs">
              <a href={`/login?address=${data.token_address}`} style={{ color: '#fff' }}>login</a>
            </button>)}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
