import React, { useState, useContext, useEffect, useRef } from 'react';
import { useRequest } from 'ahooks';
import UserContext from '../../UserContext';
import { useLocation } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import qs from 'qs';
import md5 from "js-md5";
import { Button, Checkbox, Form, Input, message } from 'antd';

import './index.css'

export default function Login() {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [form, setForm] = useState({
    user_name: '',
    password: '',
    autologin: false,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const loginRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/login/signIn',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const cadu_userInfo = window.localStorage.getItem('cadu_userInfo')
    if (query.cmd === 'logout') {
      window.localStorage.setItem('token', undefined);
      window.location.href = '/login';
    }
    console.log(cadu_userInfo,'cadu_userInfo')
    if (cadu_userInfo) {
      let userObj = JSON.parse(cadu_userInfo)
      console.log(userObj)
      setForm(userObj)
    }
  }, []);

  const onFinish = async (values) => {
    const token = await executeRecaptcha('login');
    // password: md5(values.password),
    loginRequest.run({ ...values, token: token, autologin: undefined }).then(res => {
      if (res.data) {
        window.localStorage.setItem('token', res.data.token);
        if (res.code === 0 && res.data) {
          const query = qs.parse(location.search, { ignoreQueryPrefix: true });
          if (query.returnTx) {
            window.location.href = '/tx/' + query.returnTx;
          } else if (query.address) {
            window.location.href = `/token/${query.address}`
          } else {
            window.location.href = '/myaccount';
          }
          if (form.autologin) {
            window.localStorage.setItem('cadu_userInfo', JSON.stringify(form))
          } else {
            window.localStorage.removeItem('cadu_userInfo')
          }
        } else if (res.code === 10081) {
          window.sessionStorage.setItem('key', res.data.key)
          window.location.href = `/promptActive?email=${res.data.email}`
        }
      }
    });
  };

  const showError = typeof loginRequest.data?.code !== 'undefined' && loginRequest.data?.code !== 0;

  return (
    <main id="content" role="main">
      <div className="container space-2">
        <div className='js-validate w-md-50 mx-md-auto'>
          <div className="mb-4">
            <h2 className="h3 text-primary font-weight-normal mb-0">
              Welcome <span className="font-weight-semi-bold">back</span>
            </h2>
            <p>Login to your account</p>
          </div>
          <div className="alert alert-danger" style={{ display: showError ? 'block' : 'none' }}>
            {loginRequest.data?.enMsg}
          </div>
          <Form
            name="form"
            layout="vertical"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}>

            <Form.Item
              label="Username"
              name="user_name"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="/lostpassword">
                Forgot password
              </a>
            </Form.Item>
            <Form.Item className='end-item'>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
              <p>Or <a href="/register">Click to sign up</a></p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </main>
  );
}
