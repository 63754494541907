import { useState, useEffect, useContext } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router';
import { message } from 'antd';
import qs from 'qs';
import UserContext from '../../UserContext';

export default function ChangeEmailActivate() {
    const location = useLocation();
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        body: {
            token: query.token || '1',
        },
    });

    const activateRequest = useRequest(
        body => ({
            url: '/blockBrowser/user/userInfo/emailChange',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    useEffect(() => {
        activateRequest.run(state.body);
    }, [state])

    const res = activateRequest.data || [];
    console.log(res, 'resresres');
    if (res.code == 0 && !user.token) {
        window.location.href = `/login`;
    } else if (res.code == 0 && user.token) {
        window.location.href = `/myaccount`;
    } else if(res.code == 10085){
        message.warning(res.enMsg)
        // window.location.href = `/promptActive?email=${form.email}`;
    }

    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className="w-lg-50 mx-md-auto">ChangeEmailActivate</div>
            </div>
        </main>
    )
}

