import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { message, Input } from 'antd'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';
import '../MyNotesAddress/index.css'

const { Search } = Input;
function Augment({ user, show, onHide }) {
  const [fields, setFields] = useState({
    tx_hash: '',
    note: ''
  });
  const [addResult, setAddResult] = useState({});
  const noteCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const handleAddTxnNotes = () => {
    noteCreateRequest.run(fields).then(res => {
      if (res.code == 0) {
        setAddResult(res)
        setFields({
          tx_hash: '',
          note: ''
        })
        setTimeout(() => {
          onHide();
          setAddResult({})
        }, 1500);
      }
    });
  };
  const showError = typeof noteCreateRequest.data?.code !== 'undefined' && noteCreateRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Add a new Txn hash note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          {addResult.code === 0 ? (
            <div className="alert alert-success">
              <strong>Status:</strong>Successfully Added new Txn hash private note.
            </div>
          ) : ''}
          <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
            <strong>Status: </strong>
            {noteCreateRequest.data?.enMsg}
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Txnhash">&nbsp;Txn Hash :</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtTxnHash"
                type="text"
                maxLength="66"
                id="ContentPlaceHolder1_txtTxnHash"
                className="form-control"
                required=""
                data-msg="Please enter a valid Txn hash"
                data-error-class="u-has-error"
                data-success-class="u-has-success"
                value={fields.tx_hash}
                onChange={e => {
                  setFields({ ...fields, tx_hash: e.target.value });
                }}
                placeholder="Enter a Txn hash" />
              <span id="ContentPlaceHolder1_rfvTxnHash" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }} ><br /> *Invalid Txn hash</span>
              <p></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Txnhash">&nbsp;View / Update Private Note :</label>
            <div className="input-group input-group-sm col-sm-12">
              <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
                rows="2"
                cols="20"
                id="ContentPlaceHolder1_txtPrivateNoteArea"
                spellCheck="false"
                className="form-control"
                value={fields.note}
                onChange={e => {
                  setFields({ ...fields, note: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip"></span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddTxnNotes} />
      </Modal.Footer>
    </Modal>
  )
}

function Edit({ data = {}, user, show, onHide }) {
  const [fields, setFields] = useState({
    note_id: data.note_id || '',
    note: data.note || '',
  });
  const [editResult, setEditResult] = useState({});

  const noteEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const editConfirm = () => {
    if (!fields.note) {
      message.warning('Private Note Cannot be empty!')
      return
    };
    noteEditRequest.run(fields).then(res => {
      if (res.code == 0) {
        setEditResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    });
  };
  const showError = typeof noteEditRequest.data?.code !== 'undefined' && noteEditRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title> Add a new Txn hash note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Transaction Note updated successfully.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {noteEditRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Txnhash">&nbsp;Txn Hash :</label>
            <div className="js-form-message input-group input-group-sm col-sm-12">
              <input name="ctl00$ContentPlaceHolder1$txtTxnHash"
                id="ContentPlaceHolder1_txtTxnHash"
                className="form-control"
                value={data.tx_hash}
                readonly="" />
              <span id="ContentPlaceHolder1_rfvTxnHash" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }}><br /> *Invalid Txn hash</span>
              <p></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 mt-12" htmlFor="Txnhash">&nbsp;Update Private Note :</label>
            <div className="input-group input-group-sm col-sm-12">
              <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea" rows="2" cols="20" id="ContentPlaceHolder1_txtPrivateNoteArea" spellCheck="false" className="form-control"
                value={fields.note}
                onChange={e => {
                  setFields({ ...fields, note: e.target.value });
                }}
              />
            </div>
            <div className="small text-secondary mt-1 ml-3">
              <span id="privatenotetip"></span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Save Changes" className="btn btn-sm btn-primary btn-xs" onClick={editConfirm} />
      </Modal.Footer>
    </Modal>
  )
}

function Remove({ data = {}, user, show, onHide }) {
  const [delResult, setDelResult] = useState({});
  const noteDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleAddTxnNotes = () => {
    noteDeleteRequest.run({ note_id: data.note_id }).then(res => {
      if (res.code == 0) {
        setDelResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    })
  };
  const showError = typeof noteDeleteRequest.data?.code !== 'undefined' && noteDeleteRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {delResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully removed private note '{data.tx_hash}'.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {noteDeleteRequest.data?.enMsg}
        </div>
        <p>Are you sure you wish to remove the private note?<br />
          <span className="text-secondary font-weight-normal" id="removetxhash">{data.tx_hash}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Close
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddTxnNotes} />
      </Modal.Footer>
    </Modal>
  )
}

function MynotesTxList({ user, update, filter }) {

  const [showView, setShowView] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [curTxnNote, setCurTxnNote] = useState();

  let state = {
    start: '1',
    length: '50',
    filter: filter || ''
  }

  const listTxnNoteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    listTxnNoteRequest.run(state);
  }, [update, filter]);

  const data = listTxnNoteRequest.data?.txn_note_list || [];
  const counts = listTxnNoteRequest.data?.counts || 0;
  const limit_counts = listTxnNoteRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.length);

  const handleCreateHide = () => {
    listTxnNoteRequest.run(state);
    setShowView(false);
  };
  const handleCreateHideRemove = () => {
    listTxnNoteRequest.run(state);
    setRemoveView(false);
  };
  return (
    <div className="card-body">
      <span id="spanResult"></span>
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
          <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{ display: 'none', marginTop: '4px', marginRight: '4px' }}>
            &nbsp;
          </i>
          &nbsp;{counts} used (Out of {limit_counts} max quota)
        </p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure id="SVGdataReport1" className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Transaction Private Note</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top">
                          <a
                            data-toggle="modal"
                            data-target="#responsive"
                            className="btn btn-outline-secondary btn-xss html-link-custom mr-1"
                            rel="tooltip"
                            title=""
                            onClick={() => {
                              setShowView(!showView);
                              setCurTxnNote(item)
                            }}
                            data-original-title="View/Update TxNote"
                          >
                            Edit
                          </a>
                          <a
                            data-toggle="modal"
                            data-target="#myModaldelete"
                            className="btn btn-outline-secondary btn-xss html-link-custom"
                            onClick={() => {
                              setRemoveView(!removeView);
                              setCurTxnNote(item)
                            }}
                            rel="tooltip"
                            title=""
                            data-original-title="Remove TxNote"
                          >
                            Remove
                          </a>
                        </td>
                        <td className="align-top">
                          <span className="d-block">
                            <a href={`/tx/${item.tx_hash}`}>
                              <span className="text-primary">{item.tx_hash}</span>
                            </a>
                          </span>
                          <span className="d-block small text-break" id={item.note_id}>
                            {item.note}
                          </span>
                        </td>
                        <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                      </tr>
                    )
                  })
                ) : listTxnNoteRequest.loading ? (
                  <tr />
                ) : (
                  <tr>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to create an Txn Private Notes.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      {showView && <Edit data={curTxnNote} show={showView} user={user} onHide={handleCreateHide} />}
      <Remove show={removeView} data={curTxnNote} user={user} onHide={handleCreateHideRemove} />
    </div>
  )
}

export default function MyNotesTx() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isFlag, setIsFlag] = useState(false);

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_tx" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Transactions Private Notes</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a
                      href="#"
                      id="ContentPlaceHolder1_addnew"
                      data-toggle="modal"
                      data-target="#responsive"
                      rel="tooltip"
                      title=""
                      className="btn btn-xss btn-primary rounded"
                      // onClick={addNewNote}
                      onClick={() => {
                        setShow(!show);
                      }}
                      data-original-title="Add a new transaction private note"
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  {searchStr && <div className="btn-group order-2 order-md-1 ml-2 mr-md-2" role="group">
                    <span className='btn btn-xss btn-soft-secondary ml-auto'>Filtered by
                      <span className='hash-tag hash-tag text-truncate font-weight-bold ml-2'>{searchStr}</span>
                    </span>
                    <a className="btn btn-sm btn-icon btn-soft-secondary" href="javascript:;"
                      onClick={() => setSearchStr('')}
                    >
                      <i className='fa fa-times btn-icon__inner' />
                    </a>
                  </div>}
                  <div className="position-relative order-1 order-md-2">
                    <a
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      onClick={() => setIsFlag(!isFlag)}
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    {isFlag && <div className="searchBox">
                      <Search
                        placeholder="Search Txn Private Note"
                        allowClear
                        enterButton="Find"
                        size="large"
                        onSearch={(value) => {
                          setSearchStr(value)
                          setIsFlag(!isFlag)
                        }}
                      />
                    </div>}
                  </div>
                </div>
              </div>
              <MynotesTxList user={user} update={show} filter={searchStr} />
            </div>
          </div>
          <Augment user={user} show={show} onHide={() => setShow(!show)} />
        </div>
      </div>
    </main>
  );
}
