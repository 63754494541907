import { useEffect, useState, useRef } from 'react';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { useRequest } from 'ahooks';
import Modal from 'react-bootstrap/Modal';

export default function MoreInfo({ data, user, addressRequest }) {
  console.log(user, '0000000000000');
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();

  // 编辑
  const adsEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  // 创建
  const adsNoteCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const adsNoteDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/addressNote/noteDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const onFinish = (values) => {
    if (data.private_address_note_id == 0) {
      adsNoteCreateRequest.run({ ...values, address: data.address }).then(res => {
        if (res.code !== 0) {
          message.error(res.enMsg)
        } else {
          message.success(res.enMsg)
        }
        setShowModal(false)
        addressRequest.run()
      })
    } else {
      if (!values.tag && !values.note) {
        adsNoteDeleteRequest.run({ note_id: data.private_address_note_id }).then(res => {
          if (res.code == 0) {
            message.success(res.enMsg)
          } else {
            message.error(res.enMsg)
          }
          setShowModal(false)
          addressRequest.run()
        })
      } else {
        adsEditRequest.run({ ...values, note_id: data.private_address_note_id }).then(res => {
          if (res.code !== 0) {
            message.error(res.enMsg)
          } else {
            message.success(res.enMsg)
          }
          setShowModal(false)
          addressRequest.run()
        })
      }
    }

  };

  const onReset = () => {
    form.setFieldsValue({
      tag: '',
      note: ''
    });
    setShowModal(false)
  }

  return (
    <div className="card h-100">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <h2 className="card-header-title mr-1">More Info</h2>
        </div>
        {/* <div className="d-flex">
          <div className="ml-auto mr-1"></div>
          <div className="ml-auto mr-1">
            <span data-toggle="tooltip" data-placement="top" data-title="Add address to Watch List" data-original-title="" title="">
              <a className="btn btn-xs btn-icon btn-soft-info" href="/myaddress?cmd=addnew&a=${address}#add">
                <i className="fa fa-heart btn-icon__inner"></i>
              </a>
            </span>
          </div>
          <div className="position-relative">
            <a
              className="btn btn-xs btn-icon btn-soft-secondary"
              href="#"
              role="button"
              aria-controls="dropdownTools-2"
              aria-haspopup="true"
              aria-expanded="false"
              data-unfold-event="click"
              data-unfold-target="#dropdownTools-2"
              data-unfold-type="css-animation"
              data-unfold-duration="300"
              data-unfold-delay="300"
              data-unfold-hide-on-scroll="false"
              data-unfold-animation-in="slideInUp"
              data-unfold-animation-out="fadeOut"
            >
              <i className="fa fa-ellipsis-v btn-icon__inner"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden" aria-labelledby="dropdownToolsInvoker-2" style={{ animationDuration: '300ms' }}>
              <a className="dropdown-item" data-toggle="modal" data-target="#responsive" title="Attach a private note to this address">
                <i className="far fa-sticky-note mr-1"></i> View Private Note
              </a>
              <a className="dropdown-item" href="/balancecheck-tool?a=${address}">
                <i className="fa fa-fw fa-history  mr-1"></i>Check Previous Balance
              </a>
              <hr className="my-1" />
              <a className="dropdown-item" href="/contactus?id=5&a=${address}">
                <i className="fa fa-fw fa-user-tag mr-1"></i>Update Name Tag
              </a>
              <a className="dropdown-item" href="/contactus?id=5&a=${address}">
                <i className="fa fa-fw fa-tags mr-1"></i>Submit Label
              </a>
              <a className="dropdown-item" href="/contactus?id=6">
                <i className="fa fa-fw fa-flag mr-1"></i>Report/Flag Address
              </a>
              <a className="dropdown-item" href="/tokenapprovalchecker?search=${address}">
                <i className="fas fa-user-check"></i> Token Approvals
                <sup>
                  <span className="badge badge-secondary ml-1"> Beta</span>
                </sup>
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-4 mb-1 mb-md-0">
            <i
              className="far fa-question-circle text-muted"
              data-toggle="tooltip"
              data-html="true"
              data-title="<p className='text-white text-left'>Name tags or notes can be attached to an address for identifying addresses of interest. <br /><br />(This info is private and can ONLY be viewed by you)</p>"
              data-original-title=""
              title=""
            ></i>
            My Name Tag:
          </div>
          {!data.private_address_tag ? (<div className="col-md-8">
            <span>
              Not Available
              {user.token == 'undefined' ? (<a href="/login">,login to update </a>)
                : (<a onClick={e => {
                  setShowModal(true);
                }} href="#">
                  &nbsp;&nbsp;Update?
                </a>)}
            </span>
          </div>) : (<div className="col-md-8">
            <span className="u-label u-label--value u-label--secondary text-dark">{data.private_address_tag}</span>
            <Tooltip title="View/Update Private Name Tag or Note" onClick={() => setShowModal(true)}>
              <i className="far fa-edit text-info ml-1" />
            </Tooltip>
          </div>)}
        </div>
        {data.account_type == 2 && <hr className="hr-space" />}
        {data.account_type == 2 && <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">
              <span className="d-md-none d-lg-inline-block mr-1">Contract</span>Creator:
            </div>
            <div className="col-md-8">
              <a href={`/address/${data.creator}`} className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title="Creator Address">
                {data.creator}
              </a>
              <span className="d-none d-lg-inline-block ml-1">
                at txn &nbsp;
                <a
                  href={`/tx/${data.hash}`}
                  data-toggle="tooltip"
                  title=""
                  className="hash-tag text-truncate"
                  data-original-title="Creator Txn Hash"
                >
                  {data.hash}
                </a>
              </span>
            </div>
          </div>
        </div>}
        {(data.contract_type != 3 && data.contract_type != 0) && <hr className="hr-space" />}
        {(data.contract_type != 3 && data.contract_type != 0) && <div>
          <div className="row align-items-center">
            <div className="col-md-4 mb-1 mb-md-0">
              <span className="d-md-none d-lg-inline-block mr-1">Token</span>Tracker:
            </div>
            <div className="col-md-8">
              <img src='/images/main/empty-token.png' width="13px" />&nbsp;
              <a href={`/token/${data.address}`} className="hash-tag text-truncate" data-toggle="tooltip" title="" style={{ maxWidth: '220px' }} data-original-title="Creator Address">
                {data.name}({data.symbol})
              </a>
            </div>
          </div>
        </div>}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="p-2">
          <Modal.Title>
            <h5 className='pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2'>
              My Address - Private Name Tag or Note <a href="/mynotes_address" style={{ color: '#3d1ff5' }}>[View All]</a>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Form onFinish={onFinish} form={form}>
          <Modal.Body>
            <Form.Item label="My Name Tag:" name='tag' initialValue={data.private_address_tag} extra="Private Name Tags (up to 35 characters) can be used for easy identification of addresses">
              <Input maxLength={35} />
            </Form.Item>
            <Form.Item name='note' label="Private Note:" initialValue={data.private_address_note} className='ml-2' extra="A private note (up to 500 characters) can be attached to this address.Please DO NOT store any passwords or private keys here.">
              <Input.TextArea maxLength={500} />
            </Form.Item>
          </Modal.Body>
          <Modal.Footer >
            <Form.Item>
              <Button htmlType="button" onClick={onReset} className="mr-4">
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Save Change
              </Button>
            </Form.Item>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
