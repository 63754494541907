import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';

export default function TxsPending() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length:JSON.parse(sessionStorage.getItem('2')),
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
      field: query.a ? 'address' : undefined,
      value: query.a,
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const pendingTradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/pendingTrade/pendingTradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    pendingTradeListRequest.run(state.body);
  }, [state]);

  if (pendingTradeListRequest.loading) {
    return <Loading />;
  }

  const data = pendingTradeListRequest.data?.pending_trade_list || [];
  const counts = pendingTradeListRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const length=state.body.length;
 
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 mb-0">Pending Transactions</h1>
          </div>
        </div>
      </div>

      <div className="container space-bottom-2">
        <span id="ContentPlaceHolder1_lblAdResult"></span>
        <div id="ContentPlaceHolder1_mainrow" className="card">
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div className="d-flex flex-wrap">
              <div className="mr-2 mb-2 mb-md-0">
                <a
                  className="btn btn-xss btn-soft-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-title="Click to see Pending Transaction Pool - Time Series"
                  href="/chart/pendingtx"
                  data-original-title=""
                  title=""
                >
                  <i className="fa fa-chart-area mr-1"></i> Pending Transaction Pool
                </a>
              </div>
              <div className="mr-2 mb-2 mb-md-0"></div>
              <div className="mr-2 mt-1 mb-1"></div>
            </div>
            <div className="d-flex ml-auto">
              {query.a ? (
                <div id="ContentPlaceHolder1_divFilter" className="btn-group order-2 order-md-1 ml-2" role="group">
                  <span className="btn btn-xss btn-soft-secondary ml-auto">
                    For{' '}
                    <span className="hash-tag hash-tag text-truncate font-weight-bold">
                      <a href={`/address/${query.a}`}>{query.a}</a>
                    </span>
                  </span>
                  <a
                    className="btn btn-sm btn-icon btn-soft-secondary"
                    href="/txsPending"
                    role="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Click to clear the filter"
                  >
                    <i className="fa fa-times btn-icon__inner"></i>
                  </a>
                </div>
              ) : undefined}
              <div id="ContentPlaceHolder1_searchdiv" className="position-relative order-1 order-md-2 ml-md-2" data-toggle="tooltip" title="" data-original-title="Filter Address/TxHash">
                <a
                  id="searchFilterInvoker"
                  className="btn btn-sm btn-icon btn-primary"
                  href="#"
                  role="button"
                  aria-controls="searchFilter"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-target="#searchFilter"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                >
                  <i className="fa fa-search btn-icon__inner"></i>
                </a>
                <div
                  id="searchFilter"
                  className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut"
                  aria-labelledby="searchFilterInvoker"
                  style={{width: '265px', animationDuration: '300ms'}}
                >
                  <form action="/txsPending" method="get" className="js-focus-state input-group input-group-sm w-100">
                    <input name="cn" type="search" value="" className="form-control" placeholder="Filter by Address" />
                    <div className="input-group-append">
                      <button type="submit" className="btn btn-primary">
                        Find
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="transfers" role="tabpanel" aria-labelledby="transfers-tab">
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-md-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>A total of 288 pending txns found
                  </p>
                  <Pager current={state.body.start} total={totalPage} path="/txsPending" />
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Txn Hash</th>
                        <th scope="col">
                          <a href="/txsPending?ps=50&amp;sort=nonce&amp;order=desc&amp;m=" data-toggle="tooltip" data-placement="top" title="" data-original-title="Unsorted, click to sort">
                            Nonce
                          </a>
                        </th>
                        <th scope="col">Last Seen</th>
                        <th scope="col">
                          <a href="txsPending?ps=50&amp;sort=gaslimit&amp;order=desc&amp;m=" data-toggle="tooltip" data-placement="top" title="" data-original-title="Unsorted, click to sort">
                            Gas Limit
                          </a>
                        </th>
                        <th scope="col">
                          <a href="/txsPending?ps=50&amp;sort=gasprice&amp;order=desc&amp;m=" data-toggle="tooltip" data-placement="top" title="" data-original-title="Unsorted, click to sort">
                            Gas Price
                          </a>
                        </th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <span className="hash-tag text-truncate">
                                <a href={`/tx/${item.hash}`}>{item.hash}</a>
                              </span>
                            </td>
                            <td>{item.nonce}</td>
                            <TimeTD time={moment(Number(item.trade_time) * 1000).format()} interval={item.trade_time_interval} />
                            <td>{item.gas}</td>
                            <td>{item.gas_price} Gwei</td>
                            <td>
                              <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.from}>
                                <a href={`/address/${item.from}`}>{item.from}</a>
                              </span>
                              <a href={`/txsPending?a=${item.from}`}>
                                <i className="fa fa-filter text-secondary small" data-toggle="tooltip" title="" data-original-title="Apply filter by address"></i>
                              </a>
                            </td>
                            <td>
                              <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.to}>
                                <a href={`/address/${item.to}`}>{item.to}</a>
                              </span>
                              <a href={`/txsPending?a=${item.to}`}>
                                <i className="fa fa-filter text-secondary small" data-toggle="tooltip" title="" data-original-title="Apply filter by address"></i>
                              </a>
                            </td>
                            <td>{item.value} CAD</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <form method="post" action="./txsPending" id="ctl00">
                  <div className="d-md-flex justify-content-between my-3">
                    <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                      Show
                      <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={length}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <Pager current={state.body.start} total={totalPage} path="/txsPending" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
