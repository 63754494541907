import { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import UserContext from '../../UserContext';
import MySideBar from '../../components/MySideBar';


function Remove(props) {
    const handleAddressModify = () => {
        props.onHide();
    };
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton className="p-2">
                <Modal.Title>Confirmation Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body">
                    <p>Are you sure you wish to unlink the address<br />
                        <span className="text-secondary font-weight-normal" id="removetxhash">0x6b02de5859c8d6fcf7b4dd042730f5238fe4d35f ?</span>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={props.onHide}>
                    Close
                </button>
                <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddressModify} />
            </Modal.Footer>
        </Modal>
    )
}

export default function MyAddressModify() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const [removeView, setRemoveView] = useState(false);
    const [removeViewList, setRemoveViewList] = useState(true);

    const handleCreateHideRemove = () => {
        setRemoveViewList(false);
        setTimeout(() => {
            setRemoveViewList(true);
        }, 10);
        setRemoveView(false);
    };

    return (
        <main role="main">
            <div className="container space-1">
                <div className="row">
                    <MySideBar value="myaddressmodify" user={user} />
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-header d-md-flex justify-content-between align-items-center">
                                <div className="d-flex flex-wrap">
                                    <div className="mr-2 mb-2 mb-md-0">
                                        <h2 className="card-header-title">Address Watch Options&nbsp;
                                            <i id="spinwheel1" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{ display: 'none' }}></i>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mx-md-auto">
                                    <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                                        <form method="post" action="./myaddress_modify?id=308481&amp;a=0x6b02de5859c8d6fcf7b4dd042730f5238fe4d35f" id="form1" className="mx-md-auto">
                                            <div className="aspNetHidden">
                                                <input type="hidden"
                                                    name="__VIEWSTATE"
                                                    id="__VIEWSTATE"
                                                    value="hZj8ckAxZKoLygiivOxFynvE+M4kWGQKlJDdKVlUGPXsH+eGLvzVCupzQ33L1CFnCVlmE0gMqmRZX2NFgtp2FJDTdkom/iBJ6a2kf4sTVpaWTs49yAT1xyWidYPkJ0tGC/WL0xKY9lX7eJ6v5ms3J5Y+9YGHXbtqiHTXaiMDaYmbw9+PUVCGnGb6oXJ6fFD4lWJnOfCktQG/zpGLwUGUHVEPoV/zc2PpNKHrXlMnICUdWC9p5RaKUa+RVHEToNMvCmHoAnQlS9yj3fS/oTLNqLCNibxzYCRo+AybzttnOJcnYoGu75tnUa6vcD+BtedMLDnCDZrwYjBfOO2/C3W7owImsq+Ex6mouvzmS+xwjefWjJUNJsimqaCh7a0KpBjYSKEt4CaCNHvyDMRF5H+EzjCB2UKz734vcFd0int20YkK73CCI0G5PEpruKMuCP4MvTiqOxy34c7Hb+Tr1mTgK39CwiyCx/QAU5+YK1leG2SjqS6CI2tAABtqYV3R5Kz8EAmXX33kT+Myipe3Is8h20H1cljtzH4zYEglPpnBX1gsZpFyMlCVT+yFmtkaOo6r+rMhp8VRvruJL5V8pBouY/w4SxJcTXqZUpP+2+Y/qtOXV7am+eAZuTcYuvz4txZM/nbSuyBopNIMQhd97yIXNB1jL3Xq9RAoiBq4qIv1DGt94h8gSN6CSj32eYOmI1DPsXWI7RjcL6HkWWXQf4CI4at6jHKDA7h/GF5URnuOBD+abVA4xjyAiViDJxi+rnizlvDW4Po8HpUG1u6V6TcrxC2yRBvxOIDGUoiHIZF2Y6darXiyQsxVbQYpggL037/VhL2kA40boIfyg1MMxQKn8ZLQGh4/w63s/JDtSbiVJBqt7XTKXi5CdqvnP+kSs7xeADjr2tKQKevDPzor/Bq09LQc/uMC6FyczaWNlbXocmaZMPESUoyymfVKDq6Iff9BwPlIylS8E75m87k4jfz1jB7IzSWX8v1E1vn+PBIypQyJEm3NAIwzOkaMmerWhentak/1dJbLd2i0dCWZfwcd5Yg4NoE6w/GsdDKTMpcW+05LQT6YvS77SCYLDOlH8ITSmVlc0WRzZN3dBg2Ytkcs+bDuDMJYNHE65TEaV8Lo3Qyl9yPcPSCNhfRU9gzahvb4O9rYQN4QPlteAm8mBST0aaDoWbqGnO2gVPhEoZ70OLjAXGOEO930lb1QI8845Rq9a5n2rR4i5WRIikhuKiO04TT0m7sIaDsGJZXaWV82sK9zOsrK+qQ2hADuIsvPDG29NE/qbjsPTg3VmmpJaZaao4lrA1vlWVnA2yu3VxeVqFhPggDZo3tt1CHKVeBnwJ0suITt2eDoAq9S5OT+RDF40rZLavn5Kh9pHgCE41dxfXFhpAENVo6Yt7Hioc76v7Isq424A4kV2TAATbpTPBx9kNp1pyn5NjmtQypCCYdkcC4PQcWWj+mZo7e/dnEPdUhWRYOJGRKZ486Xc2EK31Pz+9AxhsnYsumtkki2OfFwtf0ydDTqVz7ZwnXLNElPdJSsUdtEYVkuecb+JXLHXgbYZ17uv72ZwEKy6dzy/PC06tNu8AJoPuYlf85bRsGun/bJGblJSHQMyadN+HpRirq5zHFQ9MD2NAgw0HNP+Nrrzn6qrzifK0i1S3XAJnNGdDf+3XzFesC2LQeY8OuI+uuGjA==" />
                                            </div>
                                            <div className="aspNetHidden">
                                                <input type="hidden"
                                                    name="__VIEWSTATEGENERATOR"
                                                    id="__VIEWSTATEGENERATOR"
                                                    value="456F3B4D" />
                                                <input type="hidden"
                                                    name="__EVENTVALIDATION"
                                                    id="__EVENTVALIDATION"
                                                    value="5P/WA59n3doI2WfFjt5Ypp8Is/DDfzAbJPi4TSSAAiinblur765JvgoJ03qkFgwl7y+aTuvj0q4QfvpNxS75OtLWnH87Dsk/SwD/fqaA47mCQVhVyTrEGfDfx1SIvzsj3o4O8owaHYgvk/uIdmpnOGWkQDCoixIqNYsjL4z7MImraNBUuMj5mDcTyeYQgItnNnF22tBskBqB+vzyNr1sJZmXblxa53KGP8FszbfbqAX0vSc1ud2/Sd5OTwRru+bLZZ0BvOHXy6EvnjM6gJWPrp9KLEtmN4vTm3clYymAX68=" />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3" for="Address">Address</label>
                                                <div className="col-sm-9">
                                                    <strong>0x6b02de5859c8d6fcf7b4dd042730f5238fe4d35f</strong>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3" for="Description">Description</label>
                                                <div className="input-group input-group-sm col-sm-9">
                                                    <input name="ctl00$ContentPlaceHolder1$txtDescription"
                                                        type="text"
                                                        value="1"
                                                        maxLength="100"
                                                        id="ContentPlaceHolder1_txtDescription"
                                                        className="form-control" />
                                                </div>
                                            </div>
                                            <fieldset className="form-group">
                                                <div className="row">
                                                    <legend className="col-form-label col-sm-3 pt-0">Notification Settings</legend>
                                                    <div className="col-sm-9">
                                                        <div className="custom-control custom-radio d-flex align-items-center">
                                                            <input value="RadioButton0"
                                                                name="ctl00$ContentPlaceHolder1$radio1"
                                                                type="radio"
                                                                id="ContentPlaceHolder1_RadioButton0"
                                                                className="custom-control-input"
                                                                checked="checked" />
                                                            <label className="custom-control-label" for="ContentPlaceHolder1_RadioButton0">Disable Email Notifications</label>
                                                        </div>
                                                        <div className="custom-control custom-radio d-flex align-items-center">
                                                            <input value="RadioButton1"
                                                                name="ctl00$ContentPlaceHolder1$radio1"
                                                                type="radio"
                                                                id="ContentPlaceHolder1_RadioButton1"
                                                                className="custom-control-input" />
                                                            <label className="custom-control-label" for="ContentPlaceHolder1_RadioButton1">Notify on Incoming &amp; Outgoing Txns</label>
                                                        </div>
                                                        <div className="custom-control custom-radio d-flex align-items-center">
                                                            <   input value="RadioButton2"
                                                                name="ctl00$ContentPlaceHolder1$radio1"
                                                                type="radio"
                                                                id="ContentPlaceHolder1_RadioButton2"
                                                                className="custom-control-input" />
                                                            <label className="custom-control-label" for="ContentPlaceHolder1_RadioButton2">Notify on Incoming (Receive) Txns Only</label>
                                                        </div>
                                                        <div className="custom-control custom-radio d-flex align-items-center">
                                                            <input value="RadioButton3"
                                                                name="ctl00$ContentPlaceHolder1$radio1"
                                                                type="radio"
                                                                id="ContentPlaceHolder1_RadioButton3"
                                                                className="custom-control-input" />
                                                            <label className="custom-control-label" for="ContentPlaceHolder1_RadioButton3">Notify on Outgoing (Sent) Txns Only</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="form-group row">
                                                <label className="col-sm-3" for="Description">Other Options</label>
                                                <div className="col-sm-9">
                                                    <div className="custom-control custom-checkbox d-flex align-items-center">
                                                        <input name="ctl00$ContentPlaceHolder1$chkTokenTransfer"
                                                            type="checkbox"
                                                            id="ContentPlaceHolder1_chkTokenTransfer"
                                                            className="custom-control-input"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Applicable only when 'Disable Email Notifications' is not selected" />
                                                        <label className="custom-control-label" for="ContentPlaceHolder1_chkTokenTransfer">
                                                            Also Track BEP-20 Token Transfers (click to Enable)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-sm-flex justify-content-start mb-2">
                                                <div className="d-flex mb-2">
                                                    <div>
                                                        <input type="submit"
                                                            name="ctl00$ContentPlaceHolder1$btnSave"
                                                            value="Save Changes"
                                                            id="ContentPlaceHolder1_btnSave"
                                                            className="btn btn-primary btn-xs" />
                                                    </div>
                                                    <div className="px-2">
                                                        <input type="submit"
                                                            name="ctl00$ContentPlaceHolder1$btnCancel"
                                                            value="Return"
                                                            // onClick={}
                                                            id="ContentPlaceHolder1_btnCancel"
                                                            className="btn btn-secondary btn-xs" />
                                                    </div>
                                                </div>
                                                <div className="ml-auto" onClick={() => { setRemoveView(!removeView); }}>
                                                    <a href="#" id="ContentPlaceHolder1_deleteaddress" data-toggle="modal" data-target="#myModaldelete" className="btn btn-danger btn-xs">
                                                        <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Unlink Address from Watch List"></i>&nbsp; Remove
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="modal fade" id="myModaldelete" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header p-2">
                                                            <h5 className="modal-title" id="myModalLabel">Confirmation Required</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>Are you sure you wish to unlink the address 0x6b02de5859c8d6fcf7b4dd042730f5238fe4d35f ?</p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button">Close</button>
                                                            <input type="submit"
                                                                name="ctl00$ContentPlaceHolder1$btnContinueRemove"
                                                                value="Continue"
                                                                id="ContentPlaceHolder1_btnContinueRemove"
                                                                className="btn btn-sm btn-primary btn-xs" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Remove show={removeView} onHide={handleCreateHideRemove} />
                </div>
            </div>
        </main>
    )
}
