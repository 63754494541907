import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function TimeTD({ time, interval, showAs }) {
  const now = new Date().getTime();
  const intervalTime = moment(now - Number(interval) * 1000)
    .startOf('minute')
    .fromNow();

  return (
    <>
      <td className="showDate" style={{ display: showAs === 'Date Time (UTC)' ? '' : 'none' }}>
        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom" title={intervalTime}>
          {time}
        </span>
      </td>
      <td className="showAge" style={{ display: showAs === 'Age' ? '' : 'none' }}>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>{time}</Tooltip>}>
          <span>{intervalTime}</span>
        </OverlayTrigger>
      </td>
    </>
  );
}
