import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router';
import { Spin } from 'antd';
import qs from 'qs';


export default function DeleteAccount() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        body: {
            token: query.token || '1',
        },
    });

    // deleteAccount

    // confirmdisable
    const deleteUserCheck = useRequest(
        body => ({
            url: '/blockBrowser/user/userInfo/deleteUserCheck',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    useEffect(() => {
        deleteUserCheck.run(state.body);
    }, [state])

    const res = deleteUserCheck.data || [];
    console.log(res, 'resresres');

    if (res.code == 0) {
        window.location.href = `/confirmdisable?token=${state.body.token}`;
    }
    // else if (res.code == 10083) {
    //     window.location.href = `/login`;
    // } else {

    // }

    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className="w-lg-50 mx-md-auto d-flex justify-content-center" style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div>deleteUserCheck</div>
                    <Spin size="large" className='mt-4' />
                </div>
            </div>
        </main>
    )
}