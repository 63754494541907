import { useEffect, useState, useRef } from 'react';
import { useRequest } from 'ahooks';
import qs from 'qs';
import { useLocation, useParams } from 'react-router-dom';
import { Tooltip, Collapse, Card } from 'antd';
import Copy from '../../components/Copy';

import Pager from '../../components/Pager';
import TimeTD from '../../components/TimeTD';

import './index.css'

const { Panel } = Collapse;

export default function Nft() {
    const location = useLocation();
    const { address, tokenId } = useParams();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [tokensMt, setTokensMt] = useState();
    const [showAs, setShowAs] = useState('Age');
    const [state, setState] = useState({
        body: {
            start: query.p || '1',
            // length: '50',
            // length:JSON.parse(sessionStorage.getItem('2')),
            length: JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')),
            // address: "0xe4f1710e2759e0f58cfb031c8b5cdf06daf84216",
            // token_id: "1",
            address,
            token_id: tokenId,
        },
    });

    const nftActivityListRequest = useRequest(
        body => ({
            url: '/blockBrowser/tokens/tokenTradeNft/nftActivity',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true },
    );

    const tokenTradeNftDetail = useRequest(
        body => ({
            url: '/blockBrowser/tokens/tokenTradeNft/nftDetail',
            method: 'post',
            body: JSON.stringify(body),
        }),
        // { manual: true, formatResult: r => r },
        { manual: true },
    );


    const handleChangePageSize = e => {
        setState({ ...state, body: { ...state.body, length: e.target.value } });
    };

    useEffect(() => {
        nftActivityListRequest.run(state.body);
        tokenTradeNftDetail.run({ address, token_id: tokenId })
        // tokenTradeNftDetail.run({ address: '0xfae4f708829498b226bd18ab21190b8b4bcb3040', token_id: '0' })
    }, [state]);

    const changeType = () => {
        let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
        setShowAs(str)
      }

    const nftDetail = tokenTradeNftDetail.data || {}
    const data = nftActivityListRequest.data?.activities || [];
    const counts = nftActivityListRequest.data?.count || 0;
    const token_trade_count = nftActivityListRequest.data?.count || 0;
    const totalPage = Math.ceil(Number(token_trade_count) / state.body.length);
    const length = state.body.length;

    window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

    return (
        <main role="main">
            <div className="container py-2">
                <div className="row">
                    <div className="col-md-5 px-3 mb-4">
                        <div className="card shadow-none p-3">
                            <div className="mb-0 w-100 nft-block nft-block-xl">
                                {
                                    nftDetail.image ? (<img className="nft-block-img" src={nftDetail.image} />) : (<img className="nft-block-img nft-empty-img" src='/images/main/nft-placeholder.svg' />)
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 px-3 mb-4">
                        <div className="d-sm-flex justify-content-between mb-4">
                            {nftDetail && nftDetail.name && <div className="d-md-block text-truncate mr-4">
                                <Tooltip title={nftDetail.name.name}>
                                    <span className='h5 text-dark mb-0 hash-tag text-truncate'>{nftDetail.name.name}</span>
                                </Tooltip>
                                <div className="d-flex align-items-center">
                                    <div className="mr-1">
                                        <img src="/favicon.ico" alt="" className='u-xs-avatar rounded-circle' />
                                    </div>
                                    <div className="text-truncate">
                                        <Tooltip title={nftDetail.name.nft_name}>
                                            <a className='text-truncate' href={`/token/${nftDetail.detail.contract.address}`}>{nftDetail.name.nft_name}</a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>}
                            <div className="d-flex"></div>
                        </div>
                        {/* <div className="card shadow-none p-3 mb-3">
                            <div className="row mx-gutters-1 align-items-center">
                                <div className="col-lg-4">
                                    <div className="text-muted mb-1">
                                        Min. Price<i className='fal fa-question-circle text-muted ml-1' />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title={"Displaying current value: 0.0001 ETH($0.19)"}>
                                            <span className='text-truncate fs-md'>0.0001 ETH
                                                <span className='small text-muted ml-1'>($0.19)</span>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="col-lg-4 border-md-start px-md-3">
                                    <div className="text-muted mb-1">
                                        Last Sale (Item)<i className='fal fa-question-circle text-muted ml-1' />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title={"Displaying current value: 0.0001 ETH($0.19)"}>
                                            <span className='text-truncate fs-md'>0.0001 ETH
                                                <span className='small text-muted ml-1'>($0.19)</span>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="col-lg-4 border-md-start px-md-3">
                                    <div className="text-muted mb-1">
                                        Last Sale (Contract)<i className='fal fa-question-circle text-muted ml-1' />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Tooltip title={"Displaying current value: 0.0001 ETH($0.19)"}>
                                            <span className='text-truncate fs-md'>0.0001 ETH
                                                <span className='small text-muted ml-1'>($0.19)</span>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="accordion">
                            <Collapse
                                size="small"
                                defaultActiveKey={['1']}
                                expandIconPosition="end"
                            >
                                <Panel header={<div><i className="far fa-list fa-fw mr-1" />Details</div>} key="1">
                                    {nftDetail && nftDetail.detail && <div className='accordion-body'>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Owner:
                                            </div>
                                            <div className='col-7'>
                                                {nftDetail.detail.token_standard == 'CRC-721' ? (<div className='d-flex align-items-center'>
                                                    <Tooltip title={nftDetail.detail.owner.address}>
                                                        <a href={`/address/${nftDetail.detail.owner.address}`} className="text-truncate mr-2">
                                                            {nftDetail.detail.owner.address}
                                                        </a>
                                                        <Copy text={nftDetail.detail.owner.address} title="" />
                                                    </Tooltip>
                                                </div>) : (<div className='d-flex align-items-center'>
                                                    <Tooltip title={nftDetail.detail.owner.count}>
                                                        <a href={`/token/${address}`} className="text-truncate mr-2">
                                                            {nftDetail.detail.owner.count}
                                                        </a>
                                                        <Copy text={nftDetail.detail.owner.count} title="" />
                                                    </Tooltip>
                                                </div>)}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Contract Address:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    {nftDetail.detail.contract.is_verified && <i className='fa fa-check-circle text-success' />}
                                                    <Tooltip title={nftDetail.detail.contract.address}>
                                                        <a href={`/address/${nftDetail.detail.contract.address}`} className="text-truncate mr-2">
                                                            {nftDetail.detail.contract.address}
                                                        </a>
                                                        <Copy text={nftDetail.detail.contract.address} title="" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Creator:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <Tooltip title={nftDetail.detail.creator.address}>
                                                        <a href={`/address/${nftDetail.detail.creator.address}`} className="text-truncate mr-2">
                                                            {nftDetail.detail.creator.address}
                                                        </a>
                                                        <Copy text={nftDetail.detail.creator.address} title="" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Classification:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <span className="hash-tag text-truncate mr-2">
                                                        {nftDetail.detail.offchain ? "Off-Chain" : 'On-Chain'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Token ID:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <Tooltip title={nftDetail.detail.token_id}>
                                                        <span className="hash-tag text-truncate mr-2">
                                                            {nftDetail.detail.token_id}
                                                        </span>
                                                        <Copy text={nftDetail.detail.token_id} title="" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Token Standard:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <span className="hash-tag text-truncate mr-2">
                                                        {nftDetail.detail.token_standard}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {nftDetail.detail.token_standard == 'ERC-1155' && <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Quantity:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <span className="hash-tag text-truncate mr-2">
                                                        {nftDetail.detail.quantity}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className='row mb-2'>
                                            <div className='col-5 text-muted'>
                                                <i className="fal fa-question-circle text-muted mr-1" />Marketplaces:
                                            </div>
                                            <div className='col-7'>
                                                <div className='d-flex align-items-center'>
                                                    <span className="hash-tag text-truncate mr-2">
                                                        {nftDetail.detail.marketplaces}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </Panel>
                                {nftDetail.properties && nftDetail.properties.length && <Panel header={<div><i className="far fa-cube fa-fw mr-1" />Properties({nftDetail.properties && nftDetail.properties.length})</div>} key="2">
                                    <div className='accordion-body'>
                                        <div className='row row-cols-2 row-cols-sm-3 row-cols-xl-5 mx-n1 mb-n2'>
                                            {nftDetail && nftDetail.properties && nftDetail.properties.map((item, index) => {
                                                return (<div className='col px-1 mb-2' key={index}>
                                                    <div className='card h-100 shadow-none bg-light text-center py-2 px-3'>
                                                        <span className='fs-sm fw-semibold text-info mb-0 hash-tag text-truncatee'>{item.trait_type}</span>
                                                        <Tooltip title={item.trait_value}>
                                                            <p className='text-dark mb-0 hash-tag text-truncate'>{item.trait_value}</p>
                                                        </Tooltip>
                                                        <div className='fs-sm text-muted'>Rarity: {item.rarity}%</div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </Panel>}
                                {nftDetail.description && <Panel header={<div><i className="far fa-list-alt fa-fw mr-1" />Desctiption</div>} key="3">
                                    <div>{nftDetail.description}</div>
                                </Panel>}
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-3">
                <Card title="Item Activity" bordered={false}>
                    <div className="card-body">
                        <div className="d-md-flex justify-content-between mb-4">
                            <div className="hidden-xs-down">
                                <p className="mb-2 mb-md-0">A total of {counts} record found</p>
                            </div>
                            <Pager path="/nft" current={state.body.start} total={totalPage} />
                        </div>
                        <div className="table-responsive mb-2 mb-md-0">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" width="20"><i className="fal fa-question-circle text-muted mr-1" /></th>
                                        <th scope="col">Txn Hash</th>
                                        <th scope="col">
                                            <span className='ageSpan' onClick={changeType}>{showAs}</span>
                                        </th>
                                        <th scope="col">Action</th>
                                        <th scope="col" width="30"></th>
                                        <th scope="col">Price</th>
                                        <th scope="col">From</th>
                                        <th scope="col" width="30"></th>
                                        <th scope="col">To</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <a role="button" tabIndex="0" type="button" className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                                                        <i className="far fa-eye btn-icon__inner"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    <span className="hash-tag text-truncate">
                                                        <a href={`/tx/${item.txid}`} className="myFnExpandBox_searchVal">
                                                            {item.txid}
                                                        </a>
                                                    </span>
                                                </td>
                                                <TimeTD time={item.time} interval={item.time_interval} showAs={showAs} />
                                                <td>
                                                    <span>{item.action}</span>
                                                </td>
                                                <td>
                                                    {item.price && item.price.marketplace && <img src={item.price.marketplace.image} alt="" className='u-xs-avatar ms-1' />}
                                                </td>
                                                <td>
                                                    <span className="text-truncate">
                                                        {item.price && item.price.usd}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Tooltip title={item.from}>
                                                        {nftDetail.detail && nftDetail.detail.token_standard == 'ERC-721' ? <a href={`/address/${item.from}#tokentxnsErc721`} className="hash-tag text-truncate mr-2">
                                                            {item.from}
                                                        </a> : <a href={`/address/${item.from}#tokentxsCrc1155`} className="hash-tag text-truncate mr-2">
                                                            {item.from}
                                                        </a>}
                                                        <Copy text={item.from} title="" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center">
                                                    <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                                        <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    <Tooltip title={item.to}>
                                                        {nftDetail.detail && nftDetail.detail.token_standard == 'ERC-721' ? <a href={`/address/${item.to}#tokentxnsErc721`} className="hash-tag text-truncate mr-2">
                                                            {item.to}
                                                        </a> : <a href={`/address/${item.to}#tokentxsCrc1155`} className="hash-tag text-truncate mr-2">
                                                            {item.to}
                                                        </a>}
                                                        <Copy text={item.to} title="" />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <form method="post" action="./nft">
                            <div className="d-md-flex justify-content-between my-3">
                                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                                    Show
                                    <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" defaultValue={length}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    Records
                                </div>
                                <Pager path="/nft" current={state.body.start} total={totalPage} />
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center text-secondary"></div>
                                <div className="small text-muted"></div>
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </main>
    );
}
