import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router';
import { Spin } from 'antd';
import qs from 'qs';

export default function Activate() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        body: {
            token: query.token || '1',
        },
    });

    const activateRequest = useRequest(
        body => ({
            url: '/blockBrowser/user/login/activate',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    useEffect(() => {
        activateRequest.run(state.body).then(res => {
            console.log(res, '激活结果');
            if (res.code == 10085) {
                window.location.href = `/promptActive?email=${res.data.email}`;
            } else {
                window.sessionStorage.setItem('activedResult', JSON.stringify(res))
                window.location.href = `/messageStatus`;
            }
        })
    }, [state])

    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className="w-lg-50 mx-md-auto d-flex justify-content-center" style={{ flexDirection: 'column', alignItems: 'center' }}>
                    <div>Activate</div>
                    <Spin size="large" />
                </div>
            </div>
        </main>
    )
}