import { Switch, Route } from 'react-router-dom';
import { useSize } from 'ahooks';

import Welcome from './pages/Welcome';
import Blocks from './pages/Blocks';
import BlocksForked from './pages/BlocksForked';
import Block from './pages/Block';
import Txs from './pages/Txs';
import Tx from './pages/Tx';
import Address from './pages/Address';
import Accounts from './pages/Accounts';
import Tokens from './pages/Tokens';
import Token from './pages/Token';
import Tokentxns from './pages/Tokentxns';
import TokensNft from './pages/TokensNft';
import TokentxnsNft from './pages/TokentxnsNft';
import TokensMt from './pages/TokensMt';
import TokentxnsMt from './pages/TokentxnsMt';
import Nft from './pages/Nft';
import ContractsVerified from './pages/ContractsVerified';
import VerifyContract from './pages/VerifyContract';
import Search from './pages/Search';
import TokensVolume from './pages/TokensVolume';
import TxsPending from './pages/TxsPending';
import TxsInternal from './pages/TxsInternal';
import Validators from './pages/Validators';
import ValidatorSet from './pages/ValidatorSet';
import ValidatorSetSnapshot from './pages/ValidatorSet/Snapshot';
import Error from './pages/Error';
import Apis from './pages/Apis';
import Gasprice from './pages/Gasprice';
import Login from './pages/Login';
import LostPassword from './pages/LostPassword';
import Register from './pages/Register';
import Vyper from './pages/Vyper';
import Terms from './pages/Terms';
import Charts from './pages/Charts';
import Settings from './pages/Settings';
import Pending from './pages/Pending';

import MyAccount from './pages/MyAccount';
import MyAddress from './pages/MyAddress';
import MyNotesTx from './pages/MyNotesTx';
import MyNotesAddress from './pages/MyNotesAddress';
import MyTokenIgnore from './pages/MyTokenIgnore';
import MyApiKey from './pages/MyApiKey';
import VerifiedAddresses from './pages/VerifiedAddresses';
import MyCustomAbi from './pages/MyCustomAbi';
import TokenUpdate from './pages/TokenUpdate';
import Contactus from './pages/Contactus';
import VerifyAddress from './pages/VerifyAddress';
import Audit from './pages/Audit';
import PromptActive from './pages/PromptActive'
import ChangeEmail from "./pages/ChangeEmail";
import ChangeEmailActivate from "./pages/ChangeEmailActivate";
import MessageStatus from "./pages/MessageStatus";
import Activate from "./pages/Activate";
import TokenApprovals from "./pages/TokenApprovals";
import TopNfts from "./pages/TopNfts";
import DeleteAccount from "./pages/DeleteAccount";
import Confirmdisable from "./pages/Confirmdisable";
import DelResult from "./pages/DelResult";
import OpcodeTool from "./pages/OpcodeTool";

import MyAddressModify from './pages/MyAddressModify'
import ChartTx from './pages/Chart/Tx';
// import ChartHTPrice from './pages/Chart/HTPrice';
import Crc20ActiveAddress from './pages/Chart/crc20ActiveAddress';
import ChartActiveAddress from './pages/Chart/ActiveAddress';
import Blocksize from './pages/Chart/Blocksize';
import UniqueAddress from './pages/Chart/UniqueAddress';
import TokenTransfer from './pages/Chart/TokenTransfer';
// import ChartVerifiedContracts from './pages/Chart/VerifiedContracts';
import StatMiner from './pages/Stat/Miner';

export default function SwitchRoute() {
  const header = document.getElementById('Header');
  const footer = document.getElementById('Footer');
  const body = document.querySelector('body');
  const headerSize = useSize(header);
  const footerSize = useSize(footer);
  const bodySize = useSize(body);

  return (
    <div className="avc" style={{ minHeight: `${bodySize.height - headerSize.height - footerSize.height}px` }}>
      <Switch>
        <Route path="/about">
          <div>About</div>
        </Route>
        <Route path="/accounts">
          <Accounts />
        </Route>
        <Route path="/search">
          <Search />
        </Route>
        <Route path="/users">
          <div>Users</div>
        </Route>
        <Route path="/block/:block/:forked">
          <Block />
        </Route>
        <Route path="/block/:block">
          <Block />
        </Route>
        <Route path="/blocks">
          <Blocks />
        </Route>
        <Route path="/blocks_forked">
          <BlocksForked />
        </Route>
        <Route path="/txs">
          <Txs />
        </Route>
        <Route path="/tx/:tx">
          <Tx />
        </Route>
        <Route path="/address/:address">
          <Address />
        </Route>
        <Route path="/tokens">
          <Tokens />
        </Route>
        <Route path="/token/:token">
          <Token />
        </Route>
        <Route path="/tokentxns-nft">
          <TokentxnsNft />
        </Route>
        <Route path="/tokens-volume">
          <TokensVolume />
        </Route>
        <Route path="/tokens-nft">
          <TokensNft />
        </Route>
        <Route path="/tokens-mt1155">
          <TokensMt />
        </Route>
        <Route path="/tokentxns-mt1155">
          <TokentxnsMt />
        </Route>
        <Route path="/nft/:address/:tokenId">
          <Nft />
        </Route>
        <Route path="/tokentxns">
          <Tokentxns />
        </Route>
        <Route path="/txsPending">
          <TxsPending />
        </Route>
        <Route path="/txsInternal">
          <TxsInternal />
        </Route>
        <Route path="/validators">
          <Validators />
        </Route>
        <Route exact path="/validatorset/snapshot/:block_no">
          <ValidatorSetSnapshot />
        </Route>
        <Route exact path="/validatorset">
          <ValidatorSet />
        </Route>
        <Route path="/contractsVerified">
          <ContractsVerified />
        </Route>
        <Route path="/verifyContract">
          <VerifyContract />
        </Route>
        <Route path="/miscapis">
          <Apis />
        </Route>
        <Route path="/gasprice">
          <Gasprice />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/lostpassword">
          <LostPassword />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/vyper">
          <Vyper />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/charts">
          <Charts />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/myaccount">
          <MyAccount />
        </Route>
        <Route path="/myaddress">
          <MyAddress />
        </Route>
        <Route path="/mynotes_tx">
          <MyNotesTx />
        </Route>
        <Route path="/mynotes_address">
          <MyNotesAddress />
        </Route>
        <Route path="/mytokenignore">
          <MyTokenIgnore />
        </Route>
        <Route path="/myapikey">
          <MyApiKey />
        </Route>
        <Route path="/verifiedAddresses">
          <VerifiedAddresses />
        </Route>
        <Route path="/mycustomabi">
          <MyCustomAbi />
        </Route>
        <Route path="/tokenupdate/:token/:type">
          <TokenUpdate />
        </Route>
        <Route path="/contactus/:ads/:type">
          <Contactus />
        </Route>
        <Route path="/verifyAddress/:ads">
          <VerifyAddress />
        </Route>
        <Route path="/audit">
          <Audit />
        </Route>
        <Route path="/promptActive">
          <PromptActive />
        </Route>
        <Route path="/changeEmail">
          <ChangeEmail />
        </Route>
        <Route path="/changeEmailActivate">
          <ChangeEmailActivate />
        </Route>
        <Route path="/messageStatus">
          <MessageStatus />
        </Route>
        <Route path="/activate">
          <Activate />
        </Route>
        <Route path="/tokenApprovals">
          <TokenApprovals />
        </Route>
        <Route path="/top-nfts">
          <TopNfts />
        </Route>
        <Route path="/deleteAccount">
          <DeleteAccount />
        </Route>
        <Route path="/confirmdisable">
          <Confirmdisable />
        </Route>
        <Route path="/delResult">
          <DelResult />
        </Route>
        <Route path="/opcode-tool">
          <OpcodeTool />
        </Route>
        <Route path="/myaddress_modify">
          <MyAddressModify />
        </Route>
        <Route path="/pending">
          <Pending />
        </Route>
        <Route exact path="/chart/tx">
          <ChartTx />
        </Route>
        {/* <Route exact path="/chart/htprice">
          <ChartHTPrice />
        </Route> */}
        <Route exact path="/chart/crc20-active-address">
          <Crc20ActiveAddress />
        </Route>
        <Route exact path="/chart/active-address">
          <ChartActiveAddress />
        </Route>
        <Route exact path="/chart/blocksize">
          <Blocksize />
        </Route>
        <Route exact path="/chart/unique-address">
          <UniqueAddress />
        </Route>
        <Route exact path="/chart/token-transfer">
          <TokenTransfer />
        </Route>
        {/* <Route exact path="/chart/verified-contracts">
          <ChartVerifiedContracts />
        </Route> */}
        <Route exact path="/stat/miner">
          <StatMiner />
        </Route>
        <Route path="/error">
          <Error />
        </Route>
        <Route path="/">
          <Welcome />
        </Route>
      </Switch>
    </div>
  );
}
