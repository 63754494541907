import { useState, useRef, useEffect } from 'react';
import { useRequest } from 'ahooks';
import moment from 'moment';

import ChooseDate from "../../components/ChooseDate";

const Chart = ({ data }) => {
  const containerRef = useRef();

  useEffect(() => {
    if (data) {
      const litChartData = data.map(v => {
        return { y: Number(v.total_count), formattedValue: v.total_count, dt: v.date, sendCount: v.send_count, receiveCount: v.receive_count };
      });
      const dateArr = data.map(v => {
        return v.date
      })
      window.Highcharts.setOptions({
        lang: {
          numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
        },
      });

      function totalFormat(params) {
        if (!params) return
        const str = params.toString();
        const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        return str.replace(reg, '$1,');
      }

      var chart = new window.Highcharts.chart({
        chart: {
          renderTo: containerRef.current,
          zoomType: 'x',
          resetZoomButton: {
            position: {
              align: 'left', // right by default
              verticalAlign: 'top',
              //x: -55,
              //y: 10
              x: 10,
              y: 10,
            },
            relativeTo: 'plot',
          },
        },
        title: {
          useHTML: true,
          text: ' Daily Active Caduceus Chain Address ',
        },
        subtitle: {
          text: document.ontouchstart === undefined ? `Source: ${window.location.host}<br>Click and drag in the plot area to zoom in` : 'Pinch the chart to zoom in',
        },
        xAxis: {
          type: 'category',
          categories: dateArr
        },
        yAxis: {
          title: {
            text: 'Daily Active Caduceus Chain Address ',
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, window.Highcharts.getOptions().colors[0]],
                [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        credits: {
          enabled: false,
        },

        tooltip: {
          formatter: function () {
            return (
              '<span style="font-size:10px">' +
              this.point.dt +
              '</span><br><table><tr><td style="padding:0">' +
              '[ <span style="color:' +
              this.series.color +
              '">Active Caduceus Chain Address : </a></span><b>' +
              totalFormat(this.point.formattedValue) +
              '</b> ]<br>' +
              ' ' +
              ' <br>' +
              'Receive Count: <b>' +
              totalFormat(this.point.receiveCount) +
              '</b><br>' +
              'Send Count: <b>' +
              totalFormat(this.point.sendCount) +
              '</b><br>' +
              '</td></tr></table>'
            );
          },
        },
        series: [
          {
            type: 'line',
            name: 'Daily Transaction Count',
            data: litChartData,
          },
        ],
      });
    }
  }, [data]);

  return <div className="content" ref={containerRef} style={{ height: '550px', minWidth: '310px', overflow: 'hidden' }} data-highcharts-chart="0"></div>;
}

export default function ActiveAddress() {
  const [timeType, setTimeType] = useState('daily');
  const initDate = {
    time_type: timeType,
    start_date: '',
    end_date: '',
    // start_date: moment().subtract(1, 'days').subtract(3, "months").format('YYYY-MM-DD'),
    // end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  }
  
  const [state, setState] = useState(initDate);
  const dailyTradeChartRequest = useRequest(
    body => ({
      url: '/blockBrowser/resources/chartStats/blockChainData/activeAddresses',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true }
  );

  useEffect(() => {
    dailyTradeChartRequest.run(state)
  }, [state])

  const data = dailyTradeChartRequest.data || {};

  const selectType = (type) => {
    setTimeType(type)
    setState({ ...initDate, time_type: type })
  }

  const dateChange = (value, dateString) => {
    if(dateString[0] && dateString[1]){
      setState({ ...initDate, start_date: dateString[0], end_date: dateString[1] })
    }else{
      setState({ ...initDate})
    }
  }

  const downLoadUrl = timeType == 'daily' ? `timeType=${state.time_type}&startDate=${state.start_date}&endDate=${state.end_date}` : `timeType=${state.time_type}`

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center border-bottom py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0">
              <span className="d-block">Daily Active Caduceus Chain Address</span>
            </h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small p-0 mb-0">
              <li className="breadcrumb-item">
                <a href="/charts">Charts &amp; Stats</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/charts#blockchainData" id="ContentPlaceHolder1_aChartRegion">
                  Blockchain Data
                </a>
              </li>
              <li className="breadcrumb-item active">Daily Active Caduceus Chain Address</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="card mt-3 mb-4">
          <div className="card-body">
            <span>
              The Daily Active Caduceus Chain Address chart shows the daily number of unique addresses that were active on the network as a sender or receiver.
            </span>
          </div>
        </div>
        <div className="card mb-2">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 text-center">
                <p className="mt-2 mb-2">
                  <i className="fa fa-lightbulb mr-2"></i> Lowest number of <b>{data.min_date_amount}</b> addresses on {data.min_date}
                </p>
                <hr className="d-block d-md-none hr-space-lg" />
              </div>
              <div className="col-md-6 text-center u-ver-divider u-ver-divider--left u-ver-divider--none-md">
                <p className="mt-2 mb-2">
                  <i className="fa fa-lightbulb mr-2"></i> Highest number of <b>{data.max_date_amount}</b> addresses on {data.max_date}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="d-md-flex space-top-1 align-items-center">
            <ChooseDate
              timeType={timeType}
              bodyDate={state}
              selectType={selectType}
              onChange={dateChange}
            />
          </div>
          <div className="card-body space-bottom-1">
            <Chart data={data.chart_list} />
          </div>
        </div>
        <div className="my-4 text-right">
          Download: <a style={{ color: '#3d1ff5', cursor: 'pointer' }} href={`${window.location.origin}/blockBrowser/resources/chartStats/blockChainData/download?fileName=ActiveAddress&${downLoadUrl}`}>CSV Data</a> (Attribution Required) &nbsp;&nbsp;&nbsp;
        </div>
        <br />
      </div>
    </main>
  );
}
