import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';

export default function TokentxnsNft() {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [showAs, setShowAs] = useState('Age');
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length: '50',
      // length:JSON.parse(sessionStorage.getItem('2')),
      length: JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')),
    },
  });

  const handleChangePageSize = e => {
    setState({ ...state, body: { ...state.body, length: e.target.value } });
  };

  const tokenTradeNftListRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/tokenTradeNft/tokenTradeNftList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    tokenTradeNftListRequest.run(state.body);
  }, [state]);

  if (tokenTradeNftListRequest.loading) {
    return <Loading />;
  }

  const data = tokenTradeNftListRequest.data?.token_trade_list || [];
  const counts = tokenTradeNftListRequest.data?.counts || 0;
  const token_trade_count = tokenTradeNftListRequest.data?.token_trade_count || 0;
  const totalPage = Math.ceil(Number(token_trade_count) / state.body.length);
  const length = state.body.length;

  window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

  const changeType = () => {
    let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
    setShowAs(str)
  }

  return (
    <main role="main">
      <div className="bg-light py-3">
        <div className="container">
          <h1 className="h4 mb-0">
            <div className="d-flex align-items-center">
              Non Fungible Tokens Transfers<span className="u-label u-label--xs u-label--secondary rounded color-strong ml-2">CRC-721</span>
            </div>
            <p className="mb-0 text-break small">
              <span className="small text-secondary"></span>
            </p>
          </h1>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <div className="hidden-xs-down">
                <p className="mb-2 mb-md-0">
                  <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: 'none' }}></i> A total of &gt;= {counts} txns found
                  <span className="d-block text-secondary small">(Showing the last {token_trade_count} records only)</span>
                </p>
              </div>
              <Pager path="/tokentxns-nft" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" width="20"></th>
                    <th scope="col">Txn Hash</th>
                    <th scope="col">
                      <a href="#" title="" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format" onClick={changeType}>
                        {showAs}
                      </a>
                    </th>
                    <th scope="col">From</th>
                    <th scope="col" width="30"></th>
                    <th scope="col">To</th>
                    <th scope="col">TokenID</th>
                    <th scope="col">Token</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <a role="button" tabIndex="0" type="button" className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                            <i className="far fa-eye btn-icon__inner"></i>
                          </a>
                        </td>
                        <td>
                          <span className="hash-tag text-truncate">
                            <span className="text-danger" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Error in Txn: execution reverted">
                              <strong>
                                <i className={item.status == 0 || item.status == 2 ? "fa fa-exclamation-circle" : ""}></i>
                              </strong>
                            </span>
                            <a href={`/tx/${item.trade_hash}`} className="myFnExpandBox_searchVal">
                              {item.trade_hash}
                            </a>
                          </span>
                        </td>
                        <TimeTD time={item.trade_time} interval={item.trade_time_interval} showAs={showAs} />
                        <td>
                          <a
                            className="hash-tag text-truncate"
                            href={`/address/${item.trade_from}#tokentxnsErc721`}
                            target="_parent"
                            data-toggle="tooltip"
                            title=""
                            data-original-title={item.trade_from}
                          >
                            {item.trade_from}
                          </a>
                        </td>
                        <td className="text-center">
                          <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                            <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                          </span>
                        </td>
                        <td>
                          <a className="hash-tag text-truncate" href={`/address/${item.trade_to}#tokentxnsErc721`} target="_parent" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                            {item.trade_to}
                          </a>
                        </td>
                        <td>
                          <a href={`/token/${item.token_address}?a=${item.token_id}`} className="hash-tag text-truncate">
                            {item.token_id}
                          </a>
                        </td>
                        <td>
                          <a href={`/token/${item.token_address}`}>
                            <img src="/images/main/empty-token.png" width="13px" /> {item.token_name} ({item.token_symbol})
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post" action="./tokentxns-nft">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" defaultValue={length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager path="/tokentxns-nft" current={state.body.start} total={totalPage} />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-secondary"></div>
                <div className="small text-muted"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
