import { useEffect, useState, useRef } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Input } from 'antd';

import Pager from '../../components/Pager';

const { Search } = Input;

export default function TokensNft() {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [tokensNft, setTokensNft] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [searchType, setSearchType] = useState('name');
  const [isName, setIsName] = useState(true);
  const [error, setError] = useState(false);
  const [showNameList, setShowNameList] = useState(false);
  const [state, setState] = useState({
    showSearchBox: false,
    body: {
      start: query.p || '1',
      // length: '50',
      // length:JSON.parse(sessionStorage.getItem('2')),
      length: JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')),
    },
  });
  const searchInputRef = useRef();

  const tokensNftRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/tokenNft/tokenNftList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  const searchRequest = useRequest(
    body => ({
      url: body.field === 'name' ? '/blockBrowser/tokens/tokenNft/searchName' : '/blockBrowser/tokens/tokenNft/searchAddress',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const handleChangePageSize = e => {
    setState({ ...state, body: { ...state.body, length: e.target.value } });
  };

  const handleToggleSearch = e => {
    e.preventDefault();
    setState({ ...state, showSearchBox: !state.showSearchBox });
    setShowNameList(false)
    setError(false)
    setIsName(true)
  };

  const onSearch = (value) => {
    if (!value) {
      return;
    }
    setSearchVal(value)
    let field = value.indexOf('0x') === 0 ? 'address' : 'name'
    setSearchType(field)

    searchRequest.run({ field: value.indexOf('0x') === 0 ? 'address' : 'name', value, ...state.body }).then(res => {
      setTokensNft(res.data);
      if (!res.data) {
        setError(true)
        setIsName(false)
      }
      if (field == 'name' && res.data && Array.isArray(res.data.token_nft_name_list)) {
        setShowNameList(true)
        setTokensNft(res.data);
      }

      if (field == 'address') {
        setIsName(false)
        setTokensNft(res.data);
      }
    });
  }

  useEffect(() => {
    isName && tokensNftRequest.run(state.body).then(res => {
      setTokensNft(res);
    });
  }, [state]);

  const data = tokensNft?.token_nft_list || tokensNft?.token_nft_name_list || {};
  const counts = tokensNft?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const length = state.body.length;

  window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

  const handleSort = (type) => {
    setState({ body: { ...state.body, order_type:  Number(type)  } });
  }

  return (
    <main role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 d-flex align-items-center mb-0">
              Non-Fungible&nbsp;Token Tracker<span className="u-label u-label--xs u-label--secondary rounded color-strong ml-2">CRC-721</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div className="mb-1 mb-md-0">
              <h2 className="card-header-title">Non-Fungible Tokens (NFT)</h2>
            </div>
            <div className="d-flex ml-auto">
              <div className="position-relative order-1 order-md-2">
                <a
                  className="btn btn-sm btn-icon btn-primary"
                  href="#"
                  role="button"
                  aria-controls="searchFilter"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-target="#searchFilter"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Search for Token Name or Address"
                  onClick={handleToggleSearch}
                >
                  <i className="fa fa-search btn-icon__inner"></i>
                </a>
                <div
                  className={`dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation ${state.showSearchBox ? 'slideInUp' : 'u-unfold--hidden'}`}
                  aria-labelledby="searchFilterInvoker"
                  style={{ width: '310px', animationDirection: '300ms' }}
                >
                  <Search placeholder="Search for Token Name or Address"
                    allowClear
                    enterButton="Find"
                    onSearch={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          {(error) && <div className="alert alert-warning">
            <i className='fa fa-exclamation-triangle mr-1' />
            Sorry, we were unable to locate any search result matching the token: '{searchVal}'
          </div>}

          {isName && Array.isArray(data) && data.length !== 0 ? (<div className="card-body">
            <div className="d-md-flex justify-content-between align-items-lg-center mb-4">
              <p className="mb-2 mb-md-0">
                A total of {counts} CRC-721 Token Contracts found
              </p>
              <Pager path="/tokens-nft" current={state.body.start} total={totalPage} />
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="tokens-nft-tab">
                {/* 默认列表 */}
                {!showNameList && <div className="stickyTableHead">
                  <div className="sticky-thead-cloned-wrapper" style={{ display: 'none' }}>
                    <table className="sticky-thead-cloned-table table table-text-normal table-hover mb-0 border-bottom-0" style={{ width: '1084px' }}>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" width="1" className="token-sticky-header" style={{ width: '35px' }}>
                            #
                          </th>
                          <th scope="col" className="token-sticky-header" style={{ width: '814px' }}>
                            Token
                          </th>
                          <th scope="col" width="100" className="text-nowrap" style={{ width: '127px' }}>
                            <i className="fa fa-angle-down" style={{ color: 'gray' }}></i>{' '}
                            <a href="/tokens-nft?sort=24h&order=asc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for ascending sort">
                              Transfers (24H)
                            </a>
                          </th>
                          <th scope="col" width="100" className="text-nowrap" style={{ width: '108px' }}>
                            <a href="/tokens-nft?sort=7d&order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                              Transfers (7D)
                            </a>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="table-responsive mb-2 mb-md-0">
                    <table className="table table-text-normal table-hover">
                      <thead className="thead-light sticky-thead-original-thead">
                        <tr>
                          <th scope="col" width="1" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">#</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Token</div>
                          </th>
                          <th scope="col" width="100" className="text-nowrap">
                            <div className="sticky-thead-original-th-inner-wrapper">
                              {/* <i className="fa fa-angle-down" style={{ color: 'gray' }}></i>{' '} */}
                              <a href="#" onClick={() => handleSort('24')}>
                                Transfers (24H)
                              </a>
                            </div>
                          </th>
                          <th scope="col" width="100" className="text-nowrap">
                            <div className="sticky-thead-original-th-inner-wrapper">
                              <a href="#" onClick={() => handleSort('7')}>
                                Transfers (7D)
                              </a>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(item => {
                          return (
                            <tr key={item.order_no}>
                              <td>{item.order_no}</td>
                              <td>
                                <div className="media">
                                  <img className="u-xs-avatar mr-2" src="/images/main/empty-token.png" />
                                  <div className="media-body">
                                    <h3 className="h6 mb-0">
                                      <div className="token-wrap">
                                        <a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                          {item.token_name}
                                        </a>
                                      </div>
                                    </h3>
                                  </div>
                                </div>
                              </td>
                              <td>{item.transfers_24h}</td>
                              <td>{item.transfers_seven_day}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>}
                {/* 根据名字搜索列表 */}
                {showNameList && <div className="stickyTableHead">
                  <div className="table-responsive mb-2 mb-md-0">
                    <table className="table table-text-normal table-hover">
                      <thead className="thead-light sticky-thead-original-thead">
                        <tr>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Token Contract</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Token Name</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Symbol</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Decimals</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">Official Site</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(item => {
                          return (
                            <tr key={item.order_no}>
                              <td>
                                <a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                  {item.token_address}
                                </a>
                              </td>
                              <td>
                                <img className="u-xs-avatar mr-2" src="/images/main/empty-token-w.png" />
                                {item.token_name}
                              </td>
                              <td>{item.token_symbol}</td>
                              <td>{item.decimals}</td>
                              <td>
                                {item.official_site ? <a href={item.official_site} className="text-primary" target="_blank">{item.official_site}</a> : '-'}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>}
                <form method="post" action="./tokens-nft">
                  <div className="d-md-flex justify-content-between align-items-center my-3">
                    <div className="d-flex align-items-center text-secondary mb-3 mb-md-0">
                      Show
                      <select name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage" defaultValue={length} className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <Pager path="/tokens-nft" current={state.body.start} total={totalPage} />
                  </div>
                </form>
              </div>
            </div>
          </div>) : (<div className='card-body'>
            <div className="tab-content">
              <div className="tab-pane fade show active" aria-labelledby="tokens-nft-tab">
                {!tokensNft && searchType == 'address' && <div className="alert alert-warning">
                  <i className='fa fa-exclamation-triangle mr-1' />Sorry, the address
                  <a href={`/address/${searchVal}`} className='ml-2 mr-2'>{searchVal}</a>
                  does not appear to be a valid token contract address.
                </div>}
                {tokensNft && <>
                  <div className="alert alert-success">
                    <i className='far fa-check-circle mr-1' />We found a contract with token transfer events for token
                    <a href={`/token/${tokensNft.token_address}`} className='ml-2'>{tokensNft.token_address}</a>
                  </div>
                  <div className="px-3 mb-4">
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        Total Supply:
                      </div>
                      <div className="col-md-8 col-lg-9">{tokensNft.total_supply}</div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        Name:
                      </div>
                      <div className="col-md-8 col-lg-9">{tokensNft.token_name}</div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        Symbol:
                      </div>
                      <div className="col-md-8 col-lg-9">{tokensNft.token_symbol}</div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        Decimals:
                      </div>
                      <div className="col-md-8 col-lg-9">{tokensNft.decimals}</div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        CRC-165 Interface:
                      </div>
                      <div className="col-md-8 col-lg-9">
                        {tokensNft.crc_165_interface == "YES" && <span className='u-label u-label--xs u-label--success color-strong text-uppercase rounded'>YES</span>}
                        {tokensNft.crc_165_interface == "NO" && <span className='u-label u-label--xs u-label--danger color-strong text-uppercase rounded'>NO</span>}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1 ml-2">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        <img src="/assets/svg/empty-states/shape-1.svg" width="8" className='mt-n1 mr-2' />
                        CRC-721 Support:
                      </div>
                      <div className="col-md-8 col-lg-9">
                        {tokensNft.crc_721_support == "YES" && <span className='u-label u-label--xs u-label--success color-strong text-uppercase rounded'>{tokensNft.crc_721_support}</span>}
                        {tokensNft.crc_721_support == "NO" && <span className='u-label u-label--xs u-label--danger color-strong text-uppercase rounded'>{tokensNft.crc_721_support}</span>}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1 ml-2">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        <img src="/assets/svg/empty-states/shape-1.svg" width="8" className='mt-n1 mr-2' />
                        CRC-721 Metadata Support:
                      </div>
                      <div className="col-md-8 col-lg-9">
                        {tokensNft.crc_721_metadata == "YES" && <span className='u-label u-label--xs u-label--success color-strong text-uppercase rounded'>{tokensNft.crc_721_metadata}</span>}
                        {tokensNft.crc_721_metadata == "NO" && <span className='u-label u-label--xs u-label--danger color-strong text-uppercase rounded'>{tokensNft.crc_721_metadata}</span>}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1 ml-2">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        <img src="/assets/svg/empty-states/shape-1.svg" width="8" className='mt-n1 mr-2' />
                        CRC-721 Enumerable Support:
                      </div>
                      <div className="col-md-8 col-lg-9">
                        {tokensNft.crc_721_enumerable == "YES" && <span className='u-label u-label--xs u-label--success color-strong text-uppercase rounded'>{tokensNft.crc_721_enumerable}</span>}
                        {tokensNft.crc_721_enumerable == "NO" && <span className='u-label u-label--xs u-label--danger color-strong text-uppercase rounded'>{tokensNft.crc_721_enumerable}</span>}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center my-1">
                      <div className="col-md-4 col-lg-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                        Implements CRC-721:
                      </div>
                      <div className="col-md-8 col-lg-9">
                        {tokensNft.implements_crc_721 == "YES" && <span className='u-label u-label--xs u-label--success color-strong text-uppercase rounded'>{tokensNft.implements_crc_721}</span>}
                        {tokensNft.implements_crc_721 == "NO" && <span className='u-label u-label--xs u-label--danger color-strong text-uppercase rounded'>{tokensNft.implements_crc_721}</span>}
                      </div>
                    </div>
                  </div>
                </>}
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </main>
  );
}
