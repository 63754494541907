import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { message, Col, Row, Button, Checkbox, Form, Select, Input, Tabs } from 'antd'
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';
import '../MyNotesAddress/index.css'
import './index.css'

const { Option } = Select;
const { Search } = Input;

function RejectCon({ data = {}, user, show, onHide, type }) {
    const auditTokenRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );
    const auditTagRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );
    const handleCurReject = (value) => {
        if (type == "token") {
            auditTokenRequest.run({ id: data.id, verified: 2, reject_reason: value.reject_reason }).then(res => {
                if (res.code == 0) {
                    onHide()
                } else {
                    message.error(res.enMsg)
                }
            })
        } else {
            auditTagRequest.run({ id: data.id, verified: 2, reject_reason: value.reject_reason }).then(res => {
                if (res.code == 0) {
                    onHide()
                } else {
                    message.error(res.enMsg)
                }
            })
        }

    };
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton className="p-2">
                <Modal.Title>Reason for rejection</Modal.Title>
            </Modal.Header>
            {!data.reject_reason ? (<Form onFinish={handleCurReject}>
                <Modal.Body>
                    <Form.Item name="reject_reason" rules={[{ required: true, message: 'Please input your rejection!' }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Form.Item>
                        <Button htmlType="button" onClick={onHide} className='mr-3'>Cancel</Button>
                        <Button type="primary" htmlType="submit">Reject</Button>
                    </Form.Item>
                </Modal.Footer>
            </Form>) : (<>
                <Modal.Body>
                    <p>{data.reject_reason}</p>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Button htmlType="button" onClick={onHide}>OK</Button>
                </Modal.Footer>
            </>)}
        </Modal>
    )
}

function TokenList({ user, filter, statusVal }) {
    const [isReject, setIsReject] = useState(false);
    const [itemObj, setItemObj] = useState();
    const body = {
        start: '1',
        length: '50',
        filter: filter || '',
        verified: Number(statusVal),
    }
    const tokenInfoListRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/infoList',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true },
    );

    useEffect(() => {
        tokenInfoListRequest.run(body);
    }, [filter, statusVal]);

    const handleReject = () => {
        tokenInfoListRequest.run(body);
        setIsReject(false);
    };

    const data = tokenInfoListRequest.data?.info_list || [];
    const counts = tokenInfoListRequest.data?.counts || 0;
    const totalPage = Math.ceil(Number(counts) / body.length);

    const auditTokenRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const handleApprove = (item) => {
        auditTokenRequest.run({ id: item.id, verified: 1 }).then(res => {
            if (res.code == 0) {
                message.success(res.enMsg)
                tokenInfoListRequest.run(body);
            } else {
                message.error(res.enMsg)
            }
        })
    }

    return (<>
        <nav aria-label="page navigation" className='d-md-flex justify-content-end mb-4'>
            <div>
                <Pager total={totalPage} current={body.start} path="/txs" />
            </div>
        </nav>
        <div className="table-responsive mb-2 mb-md-0">
            <div className="mx-md-auto">
                <figure className="mb-7 ie-data-report">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Commit Date</th>
                                <th scope="col">Token Address</th>
                                <th scope="col">Request Type</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(data) && data.length !== 0 ? (
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                                            <td className="align-top">
                                                <span className="hash-tag text-truncate myFnExpandBox_searchVal">{item.basic_information.contract_address}</span>
                                            </td>
                                            <td className="align-top">
                                                {item.request_type.type == 1 ? 'New/First Time Token Update' : item.request_type.type == 2 ? 'Existing Token Info Update' : 'Token/Contract Migration'}
                                            </td>
                                            {/* 1通过 2拒绝 */}
                                            <td className="align-top">
                                                {item.verified == 1 ? 'Approved' : item.verified == 2 ? 'Rejected' : 'To Be Approved'}
                                            </td>
                                            <td className="align-top">
                                                <Button type="link" style={{ paddingLeft: '0' }} href={`/tokenupdate/${item.id}/audit`} onClick={sessionStorage.setItem("itemObj", JSON.stringify(item))}>View</Button>
                                                {item.verified == 0 && <Button className='mr-2'
                                                    onClick={() => handleApprove(item)}>Approve</Button>}
                                                {item.verified == 0 && <Button danger
                                                    onClick={() => {
                                                        setIsReject(!isReject)
                                                        setItemObj(item)
                                                    }}>Reject</Button>}
                                                {item.verified == 2 && <Button danger
                                                    onClick={() => {
                                                        setIsReject(!isReject)
                                                        setItemObj(item)
                                                    }}>ReaSon</Button>}
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : tokenInfoListRequest.loading ? (
                                <tr />
                            ) : (
                                <tr>
                                    <td colSpan="5" className="align-center">
                                        <center>
                                            <div className="alert alert-secondary mt-4" role="alert">
                                                <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to Token.</span>
                                            </div>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </figure>
            </div>
        </div>
        <RejectCon show={isReject} data={itemObj} user={user} onHide={handleReject} type="token" />
    </>)
}

function AdsTag({ user, filter, statusVal }) {
    const [isReject, setIsReject] = useState(false);
    const [itemObj, setItemObj] = useState();
    const body = {
        start: '1',
        length: '50',
        filter: filter || '',
        verified: Number(statusVal),
    }
    const adsTagListRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/tagList',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true },
    );
    useEffect(() => {
        adsTagListRequest.run(body);
    }, [filter, statusVal]);

    const handleReject = () => {
        adsTagListRequest.run(body);
        setIsReject(false);
    };

    const data = adsTagListRequest.data?.tag_list || [];
    const counts = adsTagListRequest.data?.counts || 0;
    const totalPage = Math.ceil(Number(counts) / body.length);

    console.log(data, '55555555555');

    const auditAdsTagRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const handleApprove = (item) => {
        auditAdsTagRequest.run({ id: item.id, verified: 1 }).then(res => {
            if (res.code == 0) {
                message.success(res.enMsg)
                adsTagListRequest.run(body);
            } else {
                message.error(res.enMsg)
            }
        })
    }

    return (<>
        <nav aria-label="page navigation" className='d-md-flex justify-content-end mb-4'>
            <div>
                <Pager total={totalPage} current={body.start} path="/txs" />
            </div>
        </nav>
        <div className="table-responsive mb-2 mb-md-0">
            <div className="mx-md-auto">
                <figure className="mb-7 ie-data-report">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Commit Date</th>
                                <th scope="col">Contract Address</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(data) && data.length !== 0 ? (
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="align-top">{moment(item.create_time).format('YYYY-MM-DD')}</td>
                                            <td className="align-top">
                                                <span className="hash-tag text-truncate myFnExpandBox_searchVal">{item.addresses[0].address}</span>
                                            </td>
                                            {/* 1通过 2拒绝 */}
                                            <td className="align-top">
                                                {item.verified == 1 ? 'Approved' : item.verified == 2 ? 'Rejected' : 'To Be Approved'}
                                            </td>
                                            <td className="align-top">
                                                <Button type="link" style={{ paddingLeft: '0' }} href={`/contactus/${item.id}/audit`}>View</Button>
                                                {item.verified == 0 && <Button className='mr-2'
                                                    onClick={() => handleApprove(item)}>Approve</Button>}
                                                {item.verified == 0 && <Button danger
                                                    onClick={() => {
                                                        setIsReject(!isReject)
                                                        setItemObj(item)
                                                    }}>Reject</Button>}
                                                {item.verified == 2 && <Button danger
                                                    onClick={() => {
                                                        setIsReject(!isReject)
                                                        setItemObj(item)
                                                    }}>ReaSon</Button>}
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : adsTagListRequest.loading ? (
                                <tr />
                            ) : (
                                <tr className='mb-4'>
                                    <td colSpan="5" className="align-top">
                                        <center>
                                            <div className="alert alert-secondary mt-4" role="alert">
                                                <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to Address.</span>
                                            </div>
                                        </center>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </figure>
            </div>
        </div>
        <RejectCon show={isReject} data={itemObj} user={user} onHide={handleReject} type="tag" />
    </>)

}

export default function Audit() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const [searchStr, setSearchStr] = useState('');
    const [isFlag, setIsFlag] = useState(false);
    const [statusVal, setStatusVal] = useState(3);
    const [curTab, setcurTab] = useState(1);

    const tabKey = [{
        key: '1',
        label: `Token Update`,
    },
    {
        key: '2',
        label: `Add Name Tag`,
    }];

    if (user.token == 'undefined') {
        window.location.href = '/login'
    }

    return (
        <main role="main">
            <div className="container space-1">
                <div className="row">
                    <MySideBar value="audit" user={user} />
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-header d-md-flex justify-content-between align-items-center">
                                <div className="d-flex flex-wrap">
                                    <div className="mr-2 mb-2 mb-md-0">
                                        <h2 className="card-header-title mt-1">Audit</h2>
                                    </div>
                                </div>
                                <div className="d-flex ml-auto">
                                    {searchStr && <div className="btn-group order-2 order-md-1 ml-2 mr-md-2" role="group">
                                        <span className='btn btn-xss btn-soft-secondary ml-auto'>Filtered by
                                            <span className='hash-tag hash-tag text-truncate font-weight-bold ml-2'>{searchStr}</span>
                                        </span>
                                        <a className="btn btn-sm btn-icon btn-soft-secondary" href="javascript:;"
                                            onClick={() => setSearchStr('')}
                                        >
                                            <i className='fa fa-times btn-icon__inner' />
                                        </a>
                                    </div>}
                                    <div className="position-relative order-1 order-md-2">
                                        <a
                                            className="btn btn-sm btn-icon btn-primary"
                                            href="javascript:;"
                                            onClick={() => setIsFlag(!isFlag)}
                                        >
                                            <i className="fa fa-search btn-icon__inner"></i>
                                        </a>
                                        {isFlag && <div className="searchBox">
                                            <Search
                                                placeholder="Search Address Private"
                                                allowClear
                                                enterButton="Find"
                                                size="large"
                                                onSearch={(value) => {
                                                    setSearchStr(value)
                                                    setIsFlag(!isFlag)
                                                }}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <Tabs defaultActiveKey="1" items={tabKey} onChange={(key) => setcurTab(key)} />
                                    <div className="search-box">
                                        Status:&nbsp;<Select
                                            placeholder="Select status"
                                            onChange={(val) => setStatusVal(val)}
                                            style={{ width: '180px' }}
                                            defaultValue='To Be Approved'
                                            allowClear
                                        >
                                            <Option value="1">Approved</Option>
                                            <Option value="2">Rejected</Option>
                                            <Option value="3">To Be Approved</Option>
                                        </Select>
                                    </div>

                                </div>
                                {curTab == 1 && <TokenList user={user} filter={searchStr} statusVal={statusVal} />}
                                {curTab == 2 && <AdsTag user={user} filter={searchStr} statusVal={statusVal} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
