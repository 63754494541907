import { useState, useEffect, useContext } from 'react';
import { useRequest } from 'ahooks';
import UserContext from '../../UserContext';
import { Button, Form, Input } from 'antd';

const { TextArea } = Input;

export default function OpcodeTool() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const [resultObj, setResultObj] = useState({});

    const byteToOpcode = useRequest(
        body => ({
            url: '/blockBrowser/misc/developers/byteToOpcode',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinish = (value) => {
        byteToOpcode.run({ ...value }).then(res => {
            if (res.code == 0) {
                setResultObj(res)
            }
        });
    }
    const showError = typeof byteToOpcode.data?.code !== 'undefined' && byteToOpcode.data?.code !== 0;

    return (
        <main role="main">
            <div className="container py-3 mn-b3">
                <div className="border-bottom py-3 mb-3">
                    <div className="mb-2 mb-sm-0">
                        <h1 className="h4 mb-0">Bytecode to Opcode Disassembler</h1>
                    </div>
                </div>
                <p className='mb-0'>Attempts to decode the low level Contract ByteCodes to Opcodes</p>
            </div>
            <div className='container space-bottom-2'>
                <Form
                    onFinish={onFinish}
                >
                    <Form.Item name="byte_code" rules={[{ required: true, message: 'Please enter contract bytecode!' }]}>
                        <TextArea rows={14} placeholder="Enter Contract Bytecode (0x..)" />
                    </Form.Item>
                    <Form.Item className='d-flex justify-content-end'>
                        <Button type="primary" htmlType="submit">Decode</Button>
                    </Form.Item>
                </Form>
                <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
                    <strong>Status: </strong>
                    {byteToOpcode.data?.enMsg}
                </div>
                {resultObj.code == 0 && <div className='card'>
                    <div className='card-header'>
                        <h2 class="card-header-title">Decoded Bytecode:</h2>
                    </div>
                    <div className='card-body'>
                        <ul className='list-unstyled list-xs-space'>
                            <li style={{ whiteSpace: 'pre-line' }}>{resultObj.data}</li>

                        </ul>
                    </div>
                </div>}
            </div>
        </main>
    )
}