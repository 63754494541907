import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';

export default function BlocksForked() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      // length:   JSON.parse(sessionStorage.getItem('2')),
      length:JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')) ,
    },
  });

  const blocksRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/blocksForked/blocksForkedList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  useEffect(() => {
    blocksRequest.run(state.body);
  }, [state]);

  if (blocksRequest.loading) {
    return <Loading />;
  }

  const data = blocksRequest.data?.block_list || [];
  const counts = blocksRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  const length=state.body.length;
  window.sessionStorage.setItem('2',JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 mb-0">
              <i className="fa fa-code-branch mr-1 small"></i>
              <i className="fa fa-code-fork"></i>Forked Blocks
            </h1>
            <div className="text-secondary mt-1">Excluded blocks as a result of "Chain Reorganizations"</div>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div id="ContentPlaceHolder1_topPageDiv" className="d-md-flex justify-content-between mb-4">
              <div className="hidden-xs-down">
                <p className="mb-2 mb-lg-0">A total of {counts} forked blocks found</p>
              </div>
              <Pager path="/blocks_forked" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Block</th>
                    <th scope="col">
                      <a href={() => {}} onClick="switchAgeToDateTime(this)" id="lnkAgeDateTime">
                        Age
                      </a>
                    </th>
                    <th scope="col">Txn</th>
                    <th scope="col">Validator</th>
                    <th scope="col">Gas Used</th>
                    <th scope="col">Gas Limit</th>
                    <th scope="col">Reward</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => {
                    const percent = (item.gas_used / item.gas_limit) * 100;
                    const now = new Date().getTime();
                    const time = moment(now - Number(item.block_time))
                      .startOf('minute')
                      .fromNow();
                    return (
                      <tr key={item.block_no}>
                        <td>
                          <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                        </td>
                        <TimeTD time={item.block_time} interval={item.block_time_interval} />
                        <td>
                          <a href={`/txs?block=${item.block_no}`}>{item.block_trade_amount}</a>
                        </td>
                        <td>
                          <a href={`/address/${item.block_miner}`} className="hash-tag text-truncate" data-toggle="tooltip" title={item.block_miner}>
                            {item.block_miner}
                          </a>
                        </td>
                        <td>
                          {item.gas_used} <span className="small text-secondary">({percent.toFixed(2)}%)</span>
                          <div className="progress mt-1" style={{height: '2px'}}>
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{width: `${percent.toFixed(2)}%`}}
                              aria-valuenow={percent.toFixed(2)}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </td>
                        <td>{item.gas_limit}</td>
                        <td>{item.block_reward} CAD</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div id="ContentPlaceHolder1_pageRecords">
              <form method="post" action="./blocks" id="ctl00">
                <div className="d-md-flex justify-content-between my-3">
                  <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                    Show
                    <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" defaultValue={length}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Records
                  </div>
                  <Pager path="/blocks_forked" current={state.body.start} total={totalPage} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
