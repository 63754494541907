import { useState, useEffect, useContext } from 'react';
import { useRequest } from 'ahooks';

export default function DelResult() {
    const [resultObj, setResultObj] = useState({});

    useEffect(() => {
        const result = JSON.parse(sessionStorage.getItem('result'))
        console.log(result, '结果页');
        setResultObj(result)
    }, [])


    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className='js-validate w-md-50 mx-md-auto'>
                    {resultObj.code === 0 ? (
                        <div className="alert alert-success">
                            <strong>Status:</strong> Your request for account deletion is confirmed.Your account [{resultObj.data.user_name}] is now <span className='font-weight-bold text-danger'>DELETED</span>
                        </div>
                    ) : (<div className='alert alert-danger'>
                        <strong>Status: </strong>
                        {resultObj.enMsg}
                    </div>)}
                </div>
            </div>
        </main>
    )
}