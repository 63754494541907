import { useEffect, useState, useContext } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd'
import moment from 'moment';
import qs from 'qs';

import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import Pager from '../../components/Pager';
import UserContext from '../../UserContext';

export default function Transactions({ address, overview }) {
  const [showAs, setShowAs] = useState('Age');
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: '25',
    },
  });

  const contractTradeListRequest = useRequest({
    url: '/blockBrowser/blockChain/contractInternalTrade/contractTradeList',
    method: 'post',
    headers: {
      Authorization: user.token,
    },
    body: JSON.stringify({
      start: state.body.start,
      length: '25',
      field: 'address',
      value: address,
    }),
  });

  if (contractTradeListRequest.loading) {
    return <Loading />;
  }

  const handleChangePageSize = e => {
    setState({ ...state, body: { ...state.body, length: e.target.value } });
  };

  const data = contractTradeListRequest.data?.contract_trade_list || [];
  const counts = contractTradeListRequest.data?.counts || 0;
  const contract_trade_count = contractTradeListRequest.data?.contract_trade_count || 0;

  const hash1 = location.hash
  const totalPage = Math.ceil(Number(counts) / state.body.length);
  window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

  const changeType = () => {
    let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
    setShowAs(str)
  }

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="transactions-tab">
      <div className="d-md-flex align-items-center mb-3">
        <p className="mr-2 mb-2">
          <i className="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
          &nbsp;Latest {contract_trade_count} from a total of&nbsp;
          <a href={`/txs?a=${address}`} data-toggle="tooltip" className="mr-1" data-original-title="Click to view full list">
            {counts}
          </a>
          transactions
        </p>
        <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto">
          {
            parseInt(contract_trade_count) < parseInt(counts) ? <Pager path={`${window.location.pathname}`} hash={'#transactions'} current={state.body.start} total={totalPage} /> : undefined
          }
        </div>
        <div className="position-relative ml-1">
          <a
            className="btn btn-sm btn-icon btn-soft-secondary"
            href="#"
            role="button"
            aria-controls="ddlTxFilterTools"
            aria-haspopup="true"
            aria-expanded="false"
            data-unfold-event="click"
            data-unfold-target="#ddlTxFilterTools"
            data-unfold-type="css-animation"
            data-unfold-duration="300"
            data-unfold-delay="300"
            data-unfold-hide-on-scroll="true"
            data-unfold-animation-in="slideInUp"
            data-toggle="tooltip"
            title=""
            data-unfold-animation-out="fadeOut"
            data-original-title="View Options/Filter"
          >
            <i className="fa fa-ellipsis-v btn-icon__inner"></i>
          </a>
          <div
            className="dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation u-unfold--hidden fadeOut"
            style={{ width: '180px', animationDuration: '300ms' }}
            aria-labelledby="ddlTxFilter"
          >
            <a className="dropdown-item" href="/txs?a=${address}">
              <i className="fa fa-circle mr-1"></i> View Completed Txns
            </a>
            <a className="dropdown-item" href="/txsPending?a=${address}&m=hf">
              <i className="far fa-circle mr-1"></i> View Pending Txns
            </a>
            <a className="dropdown-item" href="#" data-toggle="tooltip" title="" data-original-title="Option Not Available for this Address">
              <font color="silver">
                <i className="fa fa-exclamation-circle mr-1"></i> View Failed Txns
              </font>
            </a>
            <hr className="my-1" />
            <a className="dropdown-item" href="/txs?a=${address}&f=2">
              <i className="fa fa-long-arrow-alt-right mr-1"></i> View Outgoing Txns
            </a>
            <a className="dropdown-item" href="/txs?a=${address}&f=3">
              <i className="fas fa-long-arrow-alt-left mr-1"></i> View Incoming Txns
            </a>
          </div>
        </div>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="20"></th>
              <th scope="col" width="1">
                Txn Hash
              </th>
              <th scope="col" className="d-none d-sm-table-cell">
                <div className="d-flex align-items-center">
                  <div className="mr-2">Block</div>
                  <div className="dropdown" style={{ display: 'none' }}>
                    <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-filter btn-icon__inner"></i>
                    </button>
                    <div className="dropdown-menu" style={{ minWidth: '220px' }}>
                      <div className="py-0 px-3">
                        <label className="mb-1" htmlFor="fromblock">
                          From
                        </label>
                        <input type="text" name="fromblock" className="form-control form-control-xs mb-2 js-form-message" placeholder="Block Number" />
                        <label className="mb-1" htmlFor="toblock">
                          To
                        </label>
                        <input type="text" name="toblock" className="form-control form-control-xs mb-2 js-form-message" placeholder="Block Number" />
                        <div className="d-flex">
                          <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                            <i className="far fa-filter mr-1"></i> Filter
                          </button>
                          <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <a href="#" title="" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format" onClick={changeType}>
                      {showAs}
                    </a>
                  </div>
                  <div className="dropdown" style={{ display: 'none' }}>
                    <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-filter btn-icon__inner"></i>
                    </button>
                    <div className="dropdown-menu" style={{ minWidth: '220px' }}>
                      <div className="py-0 px-3">
                        <label className="mb-1" htmlFor="fromage">
                          From
                        </label>
                        <input type="date" name="fromage" className="form-control form-control-xs mb-2" />
                        <label className="mb-1" htmlFor="toage">
                          To
                        </label>
                        <input type="date" name="toage" className="form-control form-control-xs mb-2" />
                        <div className="d-flex">
                          <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                            <i className="far fa-filter mr-1"></i> Filter
                          </button>
                          <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="d-flex align-items-center">
                  <div className="mr-2">From</div>
                  <div className="dropdown">
                    <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-filter btn-icon__inner"></i>
                    </button>
                    <div className="dropdown-menu" style={{ minWidth: '220px' }}>
                      <div className="py-0 px-3">
                        <input type="text" className="form-control form-control-xs mb-2" placeholder="Search by address e.g. 0x.." name="fromaddress" />
                        <div className="d-flex">
                          <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                            <i className="far fa-filter mr-1"></i> Filter
                          </button>
                          <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" width="30"></th>
              <th scope="col">
                <div className="d-flex align-items-center">
                  <div className="mr-2">To</div>
                  <div className="dropdown">
                    <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-filter btn-icon__inner"></i>
                    </button>
                    <div className="dropdown-menu" style={{ minWidth: '220px' }}>
                      <div className="py-0 px-3">
                        <input type="text" className="form-control form-control-xs mb-2" placeholder="Search by address e.g. 0x.." name="toaddress" />
                        <div className="d-flex">
                          <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                            <i className="far fa-filter mr-1"></i> Filter
                          </button>
                          <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">Value</th>
              <th scope="col">
                <span data-toggle="tooltip" title="" data-toggle-second="tooltip" data-original-title="(Gas Price * Gas Used by Txns) in CAD">
                  <span className="text-secondary">[Txn Fee]</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              const now = new Date().getTime();
              const time = moment(item.create_time)
                .startOf('minute')
                .fromNow();
              return (
                <tr key={item.trade_hash}>
                  <td>
                    <a role="button"
                      tabIndex="0"
                      type="button"
                      className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                      <i className="far fa-eye btn-icon__inner"></i>
                    </a>
                  </td>
                  <td>
                    <span className={item.status == 0 ? "text-danger" : "text-warning"} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Error in Txn: execution reverted">
                      <strong>
                        <i className={item.status == 0 ? "fa fa-exclamation-circle" : ""}></i>
                        <i className={item.status == 2 ? "fa fa-question-circle" : ""}></i>
                      </strong>
                    </span>
                    <a className="hash-tag text-truncate myFnExpandBox_searchVal" href={`/tx/${item.trade_hash}`}>
                      {item.trade_hash}
                    </a>
                  </td>
                  <td className="d-none d-sm-table-cell">
                    <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                  </td>
                  <TimeTD time={item.trade_time} interval={item.trade_time_interval} showAs={showAs} />
                  <td>
                    {item.public_from_tag ? (<Tooltip title={<span>{item.public_from_tag}<br />({item.trade_from})</span>}>
                      <a href={item.trade_from_type === 2 ? undefined : `/address/${item.trade_from}`}
                        className="hash-tag text-truncate"
                      >{item.public_from_tag}</a>
                    </Tooltip>) :
                      item.private_from_tag ? (<Tooltip title={<span>{<span>My Name Tag:{item.private_from_tag}<br /></span>}({item.trade_from})</span>}>
                        <a href={`/address/${item.trade_from}`}>
                          <span className='hash-tag text-truncate u-label u-label--value u-label--secondary text-dark my-n1'>{item.private_from_tag}</span>
                        </a>

                      </Tooltip>) :
                        (<Tooltip title={<span>{item.public_from_tag && <span>My Name Tag:{item.public_from_tag}<br /></span>}({item.trade_from})<br />{item.private_from_tag && <span>Public Tag:{item.private_from_tag}</span>}</span>}>
                          <a
                            style={{ color: item.trade_from_type === 2 ? '#000' : undefined }}
                            href={item.trade_from_type === 2 ? undefined : `/address/${item.trade_from}`}
                            className="hash-tag text-truncate"
                          >{item.trade_from}</a>
                        </Tooltip>)}
                  </td>
                  <td>
                    <span className={`u-label u-label--xs u-label--${item.trade_from_type === 2 ? 'warning' : 'success'} color-strong text-uppercase text-center w-100 rounded text-nowrap`}>
                      &nbsp;{item.trade_from_type === 2 ? 'OUT' : 'IN'}&nbsp;
                    </span>
                  </td>
                  <td>
                    {item.trade_to_contract && <i className='far fa-file-alt text-secondary mr-1 ' />}
                    {item.public_to_tag ? (<Tooltip title={<span>{item.public_to_tag}<br />({item.trade_to})</span>}>
                      <a href={item.trade_from_type == 2 ? `/address/${item.trade_to}` : undefined}
                        className="hash-tag text-truncate"
                      >{item.public_to_tag}</a>
                    </Tooltip>) :
                      item.private_to_tag ? (<Tooltip title={<span>{<span>My Name Tag:{item.private_to_tag}<br /></span>}({item.trade_to})</span>}>
                        <span className='hash-tag text-truncate u-label u-label--value u-label--secondary text-dark my-n1'>{item.private_to_tag}</span>
                      </Tooltip>) :
                        (<Tooltip title={<span>{item.public_to_tag && <span>My Name Tag:{item.public_to_tag}<br /></span>}({item.trade_to})<br />{item.private_to_tag && <span>Public Tag:{item.private_to_tag}</span>}</span>}>
                          <a
                            style={{ color: item.trade_from_type === 2 ? '' : '#000' }}
                            href={item.trade_from_type == 2 ? `/address/${item.trade_to}` : undefined}
                            className="hash-tag text-truncate"
                          >{item.trade_to}</a>
                        </Tooltip>)}
                  </td>
                  <td>{item.trade_amount} CAD</td>
                  <td>
                    <span className="small text-secondary">{item.trade_fee}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
        <div className="d-flex align-items-center text-secondary"></div>
        <div className="small text-muted">
          <span className="text-sm-right" style={{marginTop: '20px'}} data-toggle="tooltip" title="" data-original-title="Export records in CSV format">
            [ Download{' '}
            <a href={`/exportData?type=address&a=${address}`} target="_blank">
              <b>CSV Export</b>
            </a>
            &nbsp;<span className="fas fa-download text-secondary"></span> ]&nbsp;
          </span>
        </div>
      </div> */}
    </div>
  );
}
