import { useState, useEffect, useContext } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router';
import qs from 'qs';
import { Button, Form, Input, message, Space } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import UserContext from '../../UserContext';

export default function Confirmdisable() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const [state, setState] = useState({
        body: {
            token: query.token || '1',
        },
    });
    const { executeRecaptcha } = useGoogleReCaptcha();

    const deleteUserConfirm = useRequest(
        body => ({
            url: '/blockBrowser/user/userInfo/deleteUserConfirm',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinish = async (value) => {
        const recaptcha_token = await executeRecaptcha('login');
        deleteUserConfirm.run({ recaptcha_token, token: state.body.token, password: value.password }).then(res => {
            console.log(res, '删除结果');
            window.sessionStorage.setItem('result',JSON.stringify(res))
            // window.sessionStorage.setItem('result',JSON.stringify(res))
            // // delResult
            window.location.href = `/delResult`;
            // if (res.code == 0) {
            //     setDelResult(res);
            // }
        })
    };

    const showError = typeof deleteUserConfirm.data?.code !== 'undefined' && deleteUserConfirm.data?.code !== 0;
    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className='js-validate w-md-50 mx-md-auto'>
                    <>
                        <div className="mb-4">
                            <h1 className='h3 text-primary font-weight-normal mb-0'>Account Deletion Confirmation</h1>
                        </div>
                        <div className='mb-4'>
                            <p>Please complete the account deletion by entering your account password to proceed.</p>
                            <p>
                                Once the deletion request is confirmed your account will be disabled and the account will be <b>DELETED</b>. You will not be able to access the account after the account is deleted and we can’t recover your account from our servers.
                            </p>
                            <p className='text-danger'>NOTE: Deleting user account will delete all the watchlists, transaction notes, private tags and verified addresses ownership.</p>
                        </div>
                        <div className='js-form-message form-group'>
                            <Form
                                layout="vertical"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="password"
                                    label="PASSWORD"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password placeholder="password" />
                                </Form.Item>
                                <Form.Item className='d-flex justify-content-end'>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Confirm
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                </div>
            </div>
        </main>
    )
}