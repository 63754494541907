import { useState, useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router';
import qs from 'qs';

export default function ChangeEmail() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [emailVal, setEmailVal] = useState('');
    const [key, setKey] = useState('');

    useEffect(() => {
        setKey(window.sessionStorage.getItem('key'))
    }, [])

    const emailChange = useRequest(
        body => ({
            url: '/blockBrowser/user/login/emailChange',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinish = (values) => {
        setEmailVal(values.emailVal)
        emailChange.run({ new_email: values.emailVal, key }).then(res => {
            if (res.code == 0) {
                window.location.href = `/PromptActive?email=${values.emailVal}`;
            }
        })
    }
    return (
        <main className="bg-light content-height" role="main">
            <div className='container space-2'>
                <div className="w-lg-50 mx-md-auto">
                    <p className='row mb-6' style={{ fontSize: '16px' }}>&lt;<a href={`/PromptActive?email=${query.email}`} className='ml-2' style={{ color: '#6c757e' }}>Return</a></p>
                    <Form onFinish={onFinish} layout="vertical">
                        <div className='mb-6'>
                            <div className='font-weight-bold mb-2'>Change Email Address</div>
                            <Form.Item
                                label="Send confirmation Email"
                                name="emailVal"
                                rules={[{ type: 'email', required: true, message: 'Please enter the correct Email Address!' }]}
                            >
                                <Input placeholder="Enter your Email Address here" />
                            </Form.Item>
                        </div>
                        <div className="row justify-content-center">
                            <Button type="primary" htmlType="submit">Send Email Now</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </main>
    )
}