
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../components/Loading';
import Pager from '../../components/Pager';
import TimeTD from '../../components/TimeTD';
import qs from 'qs';
import { Tooltip } from 'antd';

export default function Transactions({ a, token, overview }) {
  console.log(overview, 'overviewoverview');
  const [showAs, setShowAs] = useState('Age');
  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state] = useState({
    body: {
      start: query.p || '1',
      length: '25',
      field: a ? 'address' : undefined,
      value: a,
      token_address: token,
      token_types: overview.token_types,
    },
  });

  const tokenTransactionsRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenTransactions',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  const tokenIDTradeListRequest = useRequest({
    url: '/blockBrowser/tokens/address/tokenIDTradeList',
    method: 'post',
    body: JSON.stringify({
      // "token_address": "0x114d818074a00c221a5d5a16611709527f7ed4ee",
      "token_address": token,
      // "token_id": "1",
      "token_id": a,
      "start_int64": 1,
      "length_int64": 25,
      token_types: overview.token_types
    }),
  });

  let account = query.a;
  const path = window.location.href
  const path1 = path.substring(0, path.indexOf("?"))

  const changeType = () => {
    let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
    setShowAs(str)
  }

  useEffect(() => {
    tokenTransactionsRequest.run(state.body);
  }, []);

  if (tokenTransactionsRequest.loading || tokenIDTradeListRequest.loading) {
    return <Loading />;
  }
  const href=window.location.href.substring(window.location.href.indexOf('?')+1)
  const data = account != undefined && overview.token_types == 2 &&  href.length < 10  ? tokenIDTradeListRequest.data ?.token_trade_list || []: tokenTransactionsRequest.data?.token_transactions_list || [] ;
  const counts = account != undefined && overview.token_types == 2 &&  href.length < 10  ? tokenIDTradeListRequest.data?.token_trade_total : tokenTransactionsRequest.data?.counts || 0;
  const token_transactions_counts = account != undefined && overview.token_types == 2 &&  href.length < 10  ? tokenIDTradeListRequest.data?.token_trade_total || 0  :  tokenTransactionsRequest.data?.token_transactions_counts || 0;
  const token_IdTradeList_counts = tokenIDTradeListRequest.data?.token_trade_total || 0;
  const totalPage = account != undefined && overview.token_types == 2 &&  href.length < 10  ? Math.ceil(Number(token_IdTradeList_counts) / state.body.length) : Math.ceil(Number(token_transactions_counts) / state.body.length);

  return (
    <div className="tab-pane fade show active">
      <div style={{ display: 'none' }}>
        <div className="py-3 text-center">
          <img src="/images/main/loadingblock.svg" alt="Loading" />
        </div>
      </div>
      <div className="table-responsive" style={{ visibility: 'visible' }}>
        <div>
          <div className="d-md-flex justify-content-between mb-4">
            <p className="mb-2 mb-md-0">
              <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: 'none' }}></i>
              <i className="fa fa-sort-amount-desc mr-1" rel="tooltip" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
              <span>A total of {counts} transactions found</span>
              <span className="d-block small text-secondary">(Showing the last {token_transactions_counts} records)</span>
            </p>
            {
              counts > 25 ? <Pager path={`${path1}`} current={state.body.start} total={totalPage} /> : undefined
            }
          </div>
          <div className="table-responsive mb-2 mb-md-0">
            <table className="table table-md-text-normal table-hover mb-4">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Txn Hash</th>
                  <th scope="col">
                    <span className='ageSpan' onClick={changeType}>{showAs}</span>
                  </th>
                  <th scope="col">From</th>
                  <th scope="col" width="30"></th>
                  <th scope="col">To</th>
                  {state.body.token_types === 1 && <th scope="col">Quantity</th>}
                  {state.body.token_types === 4 && <th scope="col">TokenID</th>}
                  {state.body.token_types == 4 && <th scope="col">Value</th>}
                  {state.body.token_types === 2 && <th scope="col">Item</th>}
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => {
                  const now = new Date().getTime();
                  const time = moment(now - Number(item.trade_time * 1000))
                    .startOf('minute')
                    .fromNow();
                  return (
                    <tr key={i}>
                      <td>
                        <span className="hash-tag text-truncate">
                          <a href={`/tx/${item.trade_hash}`} target="_parent">
                            {item.trade_hash}
                          </a>
                        </span>
                      </td>
                      <TimeTD time={item.trade_time} interval={item.trade_time_interval} showAs={showAs} />
                      <td>
                        <a className="hash-tag text-truncate"
                          href={item.trade_from === account ? undefined : `${token}?a=${item.trade_from}`}
                          style={{ color: item.trade_from === account ? '#000' : '' }}
                          target="_parent"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={item.trade_from}>
                          {item.trade_from}
                        </a>
                      </td>
                      <td>
                        {
                          account != undefined ? (
                            <span className={`u-label u-label--xs u-label--${item.trade_from === account ? 'warning' : 'success'} color-strong text-uppercase text-center w-100 rounded text-nowrap`}>
                              &nbsp;{item.trade_from === account ? 'OUT' : 'IN'}&nbsp;
                            </span>
                          ) :
                            (
                              <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                              </span>
                            )
                        }
                      </td>
                      <td>
                        <a className="hash-tag text-truncate"
                          href={item.trade_to === account ? undefined : `${token}?a=${item.trade_to}`}
                          style={{ color: item.trade_to === account ? '#000' : '' }}
                          target="_parent"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={item.trade_to}>
                          {item.trade_to}
                        </a>
                      </td>
                      {state.body.token_types === 1 && (<td>
                        <span data-toggle="tooltip" title="" rel="tooltip" data-placement="top" data-original-title={item.token_id}>
                          {item.quantity}
                        </span>
                      </td>)
                      }
                      {state.body.token_types === 4 && (<td>
                        <Tooltip title={item.token_id}>
                          <span className="hash-tag text-truncate">{item.token_id}</span>
                        </Tooltip>
                      </td>)}

                      {state.body.token_types === 4 && (<td>
                        <Tooltip title={item.token_value}>
                          <span className="hash-tag text-truncate">{item.token_value}</span>
                        </Tooltip>
                      </td>)}

                      {/* 721 */}
                      {state.body.token_types === 2 && <td>
                        <Tooltip title={`${item.token_symbol} #${item.token_id}`}>
                          <a href={`/nft/${token}/${item.token_id}`} className="hash-tag text-truncate">
                            <img src="/images/main/empty-token.png" width="13px" />{' '}
                            <span className='mr-2 ml-2'>{item.token_symbol}</span>#{item.token_id}
                          </a>
                        </Tooltip>
                      </td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
              <div className="d-inline-block">
                <Pager path={`${path1}`} current={state.body.start} total={totalPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row mb-4">
        <div className="col text-right">
          <span className="float-right" data-toggle="tooltip" title="" data-original-title="Export records in CSV format">
            <font size="1">
              [ Download{' '}
              <a href={`/exportData?type=tokentxns&contract=${data.token_address}&a=&decimal=18`} target="_blank">
                <b> CSV Export</b>
              </a>
              &nbsp;
              <span className="fas fa-download text-secondary"></span> ]
            </font>
            &nbsp;
          </span>
        </div>
      </div> */}
    </div>
  );
}
