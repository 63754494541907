import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import { Metamask } from 'ethpay.core';
import qs from 'qs';
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import { Input, Select, Tooltip, Tabs, message, Button } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import ERC20ABI from "../../abi/ERC20.json";
import ERC721ABI from "../../abi/ERC721.json";
import ERC1155ABI from "../../abi/ERC1155.json";

import Pager from '../../components/Pager';
import './index.css'

const { Search } = Input;
let web3 = undefined;
window.Metamask = Metamask;
const ethereum = window.ethereum;
let isSubscribe = false;
const codeCon = () => {
    return (
        <div className='code-con'>
            <p className='walletconnect-qrcode__text'>使用兼容 WalletConnect 的钱包扫描二维码</p>
            <div className='mb-4'>
                <QRCode value="153453845" size={235} style={{ margin: '0 auto', display: 'block' }} />
            </div>
            <CopyToClipboard text="6596+56" onCopy={() => message.success('复制成功~')}>
                <a>复制到剪切板</a>
            </CopyToClipboard>
        </div>
    )
}
const items = [
    {
        key: '1',
        label: `二维码`,
        children: codeCon(),
    },
    {
        key: '2',
        label: `桌面`,
        children: `Content of Tab Pane 2`,
    }
];

export default function TokenApprovals() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [isShowMadal, setIsShowMadal] = useState(false);
    const [approvalList, setApprovalList] = useState([]);
    const [approveContracts, setApproveContracts] = useState([]);
    const [showTabel, setShowTabel] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [searchVal, setSearchVal] = useState('');
    const [msg, setMsg] = useState('');
    const [currentAccount, setCurrentAccount] = useState();
    const [open, setOpen] = useState(false);
    const [flag, setFlag] = useState(true);
    const [pageObj, setpageObj] = useState({
        counts: 0,
        totalPage: 0
    });
    const [state, setState] = useState({
        start: query.p || '1',
        length: JSON.parse(sessionStorage.getItem('2')) || '1',
        address: '',
        field: 'token_address',
        value: '',
    });
    const [listMesg, setListMesg] = useState(false);
    const [contractsObj, setContractsObj] = useState({
        start: query.p || '1',
        length: JSON.parse(sessionStorage.getItem('2')) || '1',
        field: "address",
        value: ""
    });

    const approvalListRequest = useRequest(
        body => ({
            url: '/blockBrowser/blockChain/approval/approvalList',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const contractSecRequest = useRequest(
        body => ({
            url: '/blockBrowser/blockChain/approval/approvedContracts',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true },
    );

    const onSearch = (value) => {
        setSearchVal(value)
        Object.assign(state, { address: value })
        Object.assign(contractsObj, { value })
        contractSecRequest.run(contractsObj).then(res => {
            setApproveContracts(res.approved_contracts)
        })
        approvalListRequest.run(state).then(res => {
            if (res.code == 0 && res.data) {
                setShowTabel(true)
                listFn(res.data)
            } else {
                setShowTabel(false)
                setListMesg(true)
                setMsg(res.data)
            }
        })
    }

    const listFn = (data) => {
        if (data) {
            setApprovalList(data.approval_list)
            setListMesg(false)
            Object.assign(pageObj, { counts: data.approval_counts })
            Object.assign(pageObj, { totalPage: Math.ceil(Number(data.approval_counts) / state.length) })
        } else {
            setApprovalList([])
        }
    }

    const handleChangePageSize = e => {
        Object.assign(state, { length: e.target.value })
        approvalListRequest.run(state).then(res => {
            listFn(res.data)
        })
    };

    const selectChange = (value) => {
        Object.assign(state, { value: value })
        approvalListRequest.run(state).then(res => {
            console.log(res, 'resresres');
            if (res.code == 0) {
                listFn(res.data)
            }

        })
    }

    const hideMadal = () => {
        setIsShowMadal(false)
    }

    const handleConnect = async (e) => {
        e.preventDefault();
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x3e900' }], // 256256 
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x3e900', // 256256 0x3e900 0x3E900
                                chainName: 'CAD-Mainnet',
                                nativeCurrency: {
                                    name: 'CAD',
                                    symbol: 'CAD',
                                    decimals: 18
                                },
                                rpcUrls: ['https://mainnet.block.caduceus.foundation'],
                                blockExplorerUrls: ['https://mainnet.scan.caduceus.foundation']
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                    hideMadal()
                    message.error(switchError.message)
                    return
                }
            } else {
                hideMadal()
                message.error(switchError.message)
                return
            }
        }


        try {
            if (!isSubscribe) {
                ethereum.on('chainChanged', (res) => {
                    isSubscribe = true;
                    message.destroy()
                    if (res != "0x3e900") {
                        message.error('Please connect to CAD-Mainnet chain only')
                        // 置灰
                        setDisabled(true)
                        return
                    }
                })
            }


        } catch (error) {

        }


        if (!web3) {
            const newAccounts = await ethereum.request({ method: 'eth_requestAccounts' });
            console.log(newAccounts[0], 'newAccountsnewAccountsnewAccounts');
            if (newAccounts[0].toLowerCase() == searchVal.toLowerCase()) {
                setDisabled(false)
                setFlag(true)
            }
            hideMadal()
            setCurrentAccount(Array.isArray(newAccounts) ? newAccounts[0] : undefined);
        }
    }

    const onReset = () => {
        web3 = undefined;
        setFlag(false)
        setCurrentAccount('')
        setDisabled(false)
    }

    const handleWallet = (e) => {
        e.preventDefault();
        hideMadal()
        setOpen(true)
    }

    const closeCode = () => { setOpen(false) }

    const handleRevoke = async (item) => {
        const provider = await Metamask.getProvider();
        web3 = new window.Web3(provider);
        if (item.token_type == 1) { // C20
            const pool = new web3.eth.Contract(ERC20ABI, item.token_address);
            const ret = await pool.methods['approve'].apply(pool.methods, [item.spender, 0]).send({ from: currentAccount })
            console.log(ret, 'c20---ret');
            if (ret.transactionHash && ret.status) {
                setTimeout(() => {
                    approvalListRequest.run(state)
                }, 3000);
            }
        } else if (item.token_type == 4) { // 1155
            const pool = new web3.eth.Contract(ERC1155ABI, item.token_address);
            const ret = await pool.methods['setApprovalForAll'].apply(pool.methods, [item.spender, false]).send({ from: currentAccount })
            console.log(ret, '1155----ret');
            if (ret.transactionHash && ret.status) {
                setTimeout(() => {
                    approvalListRequest.run(state)
                }, 3000);
            }
        } else if (item.token_type == 2) { // 721
            const pool = new web3.eth.Contract(ERC721ABI, item.token_address);
            if (item.value == 'true') {
                const ret = await pool.methods['setApprovalForAll'].apply(pool.methods, [item.spender, false]).send({ from: currentAccount })
                console.log(ret, 'true----ret');
                if (ret.transactionHash && ret.status) {
                    setTimeout(() => {
                        approvalListRequest.run(state)
                    }, 3000);
                }
            } else {
                const ret = await pool.methods['approve'].apply(pool.methods, ['0x0000000000000000000000000000000000000000', item.token_id]).send({ from: currentAccount })
                console.log(ret, '0x----ret');
                if (ret.transactionHash && ret.status) {
                    setTimeout(() => {
                        approvalListRequest.run(state)
                    }, 3000);
                }
            }
        }
    }


    return (
        <main id="content" role="main" className='space-md-1'>
            <div className="container py-3 space-bottom-3">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6">
                        <h1 className="h4 mb-3"><i className='fas fa-user-check mr-1' />Token Approvals</h1>
                        <p className='lead'>Review and revoke your token approvals for any dApp.</p>
                        {/* 0xb234ea5dc59a761038a7ea6278812aaafbd2afc4 */}
                        <Search placeholder="Search by address or Domain Name" onSearch={onSearch} enterButton size="large" allowClear defaultValue="" className='mb-2' />
                    </div>
                </div>
                {listMesg && <div className="alert alert-secondary mt-4 d-flex align-items-center justify-content-center" role="alert" style={{ width: '50%', margin: "0 auto" }}>
                    <i className="fal fa-info-circle mr-2"></i><span className="text-dark">{msg}</span>
                </div>}
            </div>

            {showTabel && (<div className="container space-bottom-3 mt-n5">
                <div className="card mb-5-alt">
                    <div className="card-header">
                        <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="d-sm-flex align-items-center">
                                {!currentAccount ? (<button type="button" className="write-btn btn btn-xs btn-secondary border disabled" onClick={() => { setIsShowMadal(true) }}><i className='fa fa-circle text-danger mr-1' />
                                    Connect to Web3
                                </button>)
                                    : (<>
                                        <Button type="button" className='write-btn btn btn-xs border btn-primary-ori mr-2'>
                                            <i className='fa fa-check-circle text-success mr-1' />
                                            Connected - Web3[<a href={`/address/${currentAccount}`} style={{ color: '#fff' }}>{currentAccount}</a>]
                                        </Button>
                                        <a href='#' onClick={onReset}>[Reset]</a>
                                    </>
                                    )}
                            </div>
                            <div className="d-sm-flex align-items-center">
                                {/* 选择的是Contract */}
                                Filter by:&nbsp;&nbsp;
                                <Select
                                    onChange={selectChange}
                                    placeholder="Filter"
                                    style={{ width: 150 }}
                                    allowClear
                                    options={approveContracts}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-md-flex justify-content-between mb-4">
                            {/* <div className="dataTables_info">Showing {approvalList && approvalList.length < 50 ? approvalList.length : state.length} of {approvalList && approvalList.length} entries</div> */}
                            <div className="dataTables_info">Showing {approvalList && approvalList.length ? approvalList.length : 0} of {approvalList && approvalList.length ? approvalList.length : 0} entries</div>
                            <Pager current={state.start} total={pageObj.totalPage} path="/tokenApprovals" />
                        </div>
                        <div className="table-responsive mb-2 mb-md-0">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Txn Hash</th>
                                        <th scope="col">Block</th>
                                        <th scope="col">Contract</th>
                                        <th scope="col">Approved Spender</th>
                                        <th scope="col">Approved Amount</th>
                                        <th scope="col">
                                            <a href="#" id="lnkAgeDateTime" title="" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format">
                                                Last Updated(UTC)
                                            </a>
                                        </th>
                                        <th scope="col">Revoke</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(approvalList) && approvalList.length !== 0 ? (
                                        approvalList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <a className="hash-tag text-truncate myFnExpandBox_searchVal" href={`/tx/${item.trade_hash}`}>
                                                            {item.trade_hash}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                                                    </td>
                                                    <td>
                                                        <img src="/images/main/empty-token.png" width="13px" />&nbsp;
                                                        <a href={`/address/${item.token_address}`}>{item.token_name}</a>
                                                    </td>
                                                    <td>
                                                        <Tooltip title={<span>{item.spender}</span>}>
                                                            <a href={`/address/${item.spender}`} >{item.spender_name}</a>
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        {item.amount}&nbsp;<a href={`/token/${item.token_address}`}>{item.token_symbol}</a>
                                                    </td>
                                                    <td className="align-top">{item.update_time}</td>
                                                    <td>
                                                        <Tooltip title={`Connect the address owner's wallet to revoke approval.`}>
                                                            <Button disabled={disabled || !flag} type="primary" icon={<FormOutlined />} onClick={() => handleRevoke(item)} />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : approvalListRequest.loading ? (
                                        <tr />
                                    ) : (
                                        <tr>
                                            <td colSpan="12" className="align-top">
                                                <center>
                                                    <div className="alert alert-secondary mt-4" role="alert">
                                                        <i className="fal fa-info-circle"></i> <span className="text-dark">No approved contracts found for the address.</span>
                                                    </div>
                                                </center>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <form method="post" action="./tokenApprovals" id="ctl00">
                            <div className="d-md-flex justify-content-between my-3">
                                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                                    Show
                                    <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={state.length}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    Records
                                </div>
                                <Pager current={state.start} total={pageObj.totalPage} path="/tokenApprovals" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>)}

            <>
                <Modal show={isShowMadal} onHide={hideMadal}>
                    <Modal.Header closeButton className="p-2">
                        <Modal.Title>Connect a Wallet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <button type='button' onClick={(e) => handleConnect(e)} className='btn btn-custom btn-custom-wallet btn-block d-flex justify-content-between align-items-center'>
                            <span className='text-dark d-flex align-items-center'>MetaMask
                                <span className='badge badge-soft-secondary badge-custom-wallet ml-2'>Popular</span>
                            </span>
                            <img src="/assets/svg/empty-states/metamask.svg" width="25" />
                        </button>
                        {/* <button type='button' onClick={(e) => handleWallet(e)} value="walletconnect" className='btn btn-custom btn-custom-wallet btn-block d-flex justify-content-between align-items-center'>
                            <span className='text-dark'>walletconnect</span>
                            <img src="/assets/svg/empty-states/walletconnect.svg" width="25" />
                        </button> */}
                    </Modal.Body>
                </Modal>
                <Modal show={open} onHide={closeCode}>
                    <Modal.Header closeButton className="p-2">
                        <Modal.Title><img src="/assets/svg/empty-states/walletconnect.svg" width="25" />&nbsp;walletconnect</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs type="card" items={items} />
                    </Modal.Body>
                </Modal>
            </>
        </main>
    )
}