import {useState} from 'react';
import qs from 'qs';

const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

const defaultState = {
  contract_address: query.a || '',
  compiler_type: undefined,
  compiler_version: undefined,
  license_type: 'NO License',
  chk_terms: true,
  Continue:false,
};
export default function StepOne({compilerLicenseList, compilerVersionList, onConfirm}) {
  const [state, setState] = useState({
    ...defaultState,
  });

  // console.log(state)
  return (
    <main role="main">
      <div className="container space-top-1 space-bottom-2">
        <form method="post" action="./verifyContract" className="js-validate mt-2" noValidate="novalidate">
          <div className="text-center mb-3">
            <div className="w-md-75 w-lg-75 mx-md-auto ">
              <h1 className="h3">Verify &amp; Publish Contract Source Code </h1>
              <small className="d-block text-muted color-strong">COMPILER TYPE AND VERSION SELECTION</small>
            </div>
          </div>
          <div className="border-top py-4">
            <div className="row">
              <div className="col-md-10 offset-md-1 text-secondary mt-3 mb-2">
                <img src="/images/undraw/undraw_Security_on_s9ym.svg" width="115" align="left" style={{marginTop: '-15px', marginBottom: '10px', marginRight: '10px'}} />
                Source code verification provides <b>transparency</b> for users interacting with smart contracts. By uploading the source code, CaduceusInfo will match the compiled code with that on the
                blockchain. Just like contracts, a "smart contract" should provide end users with more information on what they are "digitally signing" for and give users an opportunity to audit the
                code to independently verify that it actually does what it is supposed to do.
              </div>
            </div>
            <div className="w-md-75 w-lg-50 mx-md-auto mt-2">
              <div className="js-form-message form-group">
                <label className="d-block" htmlFor="txtUserName">
                  Please enter the Contract Address you would like to verify
                </label>
                <input
                  name="ctl00$ContentPlaceHolder1$txtContractAddress"
                  maxLength="42"
                  className="form-control form-control-sm"
                  required=""
                  placeholder="0x..."
                  value={state.contract_address}
                  data-success-class="u-has-success"
                  data-error-class="u-has-error"
                  data-rule-minlength="40"
                  type="search"
                  data-msg-minlength="Invalid Length"
                  data-msg="Required"
                  data-name="txtContractAddress"
                  data-rule-maxlength="42"
                  onChange={e => {
                    setState({...state, contract_address: e.target.value});
                  }}
                />
                <div id="ContentPlaceHolder1_txtContractAddress-error" className="invalid-feedback" style={{display: 'none'}}>
                  Required
                </div>
              </div>
              <div className="js-form-message form-group">
                <label className="d-block mt-4">Please select Compiler Type</label>
                <select
                  name="ctl00$ContentPlaceHolder1$ddlCompilerType"
                  defaultValue=""
                  className="custom-select custom-select-sm mb-1"
                  onChange={e => {
                    setState({...state, compiler_type: e.target.value});
                  }}
                >
                  <option value="">[Please Select]</option>
                  <option value="SingleFile" title="A simple interface for solidity code that fits or concatenated into a single file">
                    Solidity (Single file)
                  </option>
                  {/* <option value="MultiFile" title="Support for multi-part/file solidity code with imports">
                    Solidity (Multi-Part files)
                  </option>
                  <option value="Json" title="The recommended way to interface with the Solidity compiler especially for more complex and automated setups">
                    Solidity (Standard-Json-Input)
                  </option> */}
                  {/* <option value="Vyper" title="Experimental support for the Vyper smart contract development language">
                    Vyper (Experimental)
                  </option> */}
                </select>
                <div></div>
              </div>
              <span id="ContentPlaceHolder1_compilerversion">
                <div className="js-form-message form-group">
                  <label className="d-block mt-4">Please select Compiler Version</label>
                  <select
                    name="ctl00$ContentPlaceHolder1$ddlCompilerVersions"
                    id="ContentPlaceHolder1_ddlCompilerVersions"
                    className="custom-select custom-select-sm"
                    required=""
                    data-msg="Required"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    onChange={e => {
                      setState({...state, compiler_version: e.target.value});
                    }}
                  >
                    <option key="[Please Select]" value="">
                      [Please Select]
                    </option>
                    {compilerVersionList.map(item => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div id="ContentPlaceHolder1_divMajorBuildsOnly" className="custom-checkbox d-flex align-items-center text-muted" style={{marginTop: '-8px'}}>
                  <span className="ml-2">
                    <input id="ContentPlaceHolder1_chkShowAllCommits" type="checkbox" name="ctl00$ContentPlaceHolder1$chkShowAllCommits" checked="checked" onChange={() => {}} />
                  </span>
                  <small className="d-block text-muted ml-1">Un-Check to show all nightly Commits also</small>
                </div>
              </span>
              <span>
                <div className="js-form-message form-group" style={{display: 'block'}}>
                  <label className="d-block mt-4">
                    Please select Open Source License Type{' '}
                    <a href="/contract-license-types" target="_blank" data-toggle="tooltip" title="" data-original-title="Contract Source Code License Type, click for more info">
                      <i className="far fa-info-circle"></i>
                    </a>
                  </label>
                  <select
                    name="ctl00$ContentPlaceHolder1$ddlLicenseType"
                    title="Select a suitable license type"
                    className="custom-select custom-select-sm"
                    required=""
                    data-msg="Required"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    onChange={e => {
                      setState({...state, license_type: e.target.value});
                    }}
                  >
                    {compilerLicenseList.map(item => {
                      return (
                        <option key={item.symbol} value={item.symbol}>
                          {item.liscense_type}({item.symbol})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </span>
              <p align="center" className="mt-3">
                {/* <span
                  className="custom-control custom-checkbox align-items-center text-muted mb-2"
                  onClick={e => {
                    setState({...state, chk_terms: !state.chk_terms});
                  }}
                >
                  <input
                    name="chkTermsOfUse"
                    type="checkbox"
                    value={state.value}
                    className="custom-control-input align-items-center"
                    checked={state.chk_terms ? 'checked' : ''}
                    onChange={e => {
                      setState({...state, chk_terms:!state.chk_terms});
                    }}
                  />
                  <label className="custom-control-label" htmlFor="ContentPlaceHolder1_chkRemember">
                    <span>
                      I agree to the <a href="/terms">terms of service</a>
                    </span>
                  </label>
                    <p style={{display:state.Continue==false || state.chk_terms==true ?'none':'block',color:'red'}}>User protocol is not selected</p>
                </span> */}
                <input
                  type="button"
                  value="Continue"
                  id="ContentPlaceHolder1_btnSubmit"
                  className="btn btn-primary mr-2"
                  disabled={!state.chk_terms || !state.compiler_version || !state.contract_address || !state.compiler_type}
                  onClick={e => {
                    setState({...state,Continue:true})
                    let {compiler_version,  license_type} = state
                    if (!state.chk_terms || !compiler_version || !state.contract_address || !state.compiler_type) {
                      return;
                    }
                    onConfirm(state);
                  }}
                />
                <input
                  type="button"
                  value="Reset"
                  formNoValidate="formnovalidate"
                  className="btn btn-soft-secondary"
                  onClick={e => {
                    setState({...defaultState});
                  }}
                />
              </p>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}
