import { useEffect, useState, useRef } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Tooltip } from 'antd';

import Pager from '../../components/Pager';

export default function TopNfts() {
    const location = useLocation();
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [tokensNft, setTokensNft] = useState();
    const [state, setState] = useState({
        showSearchBox: false,
        body: {
            start: query.p || '1',
            // length: '50',
            // length:JSON.parse(sessionStorage.getItem('2')),
            length: JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')),
        },
    });
    const searchInputRef = useRef();

    const tokensNftRequest = useRequest(
        body => ({
            url: '/blockBrowser/tokens/tokenNft/tokenNftList',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true },
    );

    const searchRequest = useRequest(
        body => ({
            url: body.field === 'name' ? '/blockBrowser/tokens/token1155Trade/searchByName' : '/blockBrowser/tokens/token1155Trade/searchByAddress',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true },
    );

    const handleChangePageSize = e => {
        setState({ ...state, body: { ...state.body, length: e.target.value } });
    };

    const handleToggleSearch = e => {
        e.preventDefault();
        setState({ ...state, showSearchBox: !state.showSearchBox });
    };

    const handleSearch = e => {
        const v = searchInputRef.current.value;

        if (!v) {
            return;
        }

        searchRequest.run({ field: v.indexOf('0x') === 0 ? 'address' : 'name', value: v }).then(res => {
            setTokensNft(res);
        });
    };

    useEffect(() => {
        tokensNftRequest.run(state.body).then(res => {
            console.log(res, 'resresres');
            setTokensNft(res);
        });
    }, [state]);

    const data = tokensNft?.token_nft_list || tokensNft?.token_nft_list || [];
    const counts = tokensNft?.counts || 0;
    const totalPage = Math.ceil(Number(counts) / state.body.length);
    const length = state.body.length;

    window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

    return (
        <main role="main">
            <div className="container py-3">
                <div className="d-sm-flex align-items-center">
                    <div className="mb-2 mb-sm-0">
                        <h1 className="h4 d-flex align-items-center mb-0">
                            Top Nfts
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container space-bottom-2">
                <div className="card">
                    <div className="card-header d-md-flex justify-content-between align-items-center">
                        {/* <p className="mb-2 mb-md-0">
                            A total of {counts} CRC-721 Token Contracts found
                        </p> */}
                        <Pager path="/top-nfts" current={state.body.start} total={totalPage} />
                    </div>
                    <div className="card-body">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="tokens-nft-tab">
                                <div className="stickyTableHead">
                                    <div className="table-responsive mb-2 mb-md-0">
                                        <table className="table table-text-normal table-hover">
                                            <thead className="thead-light sticky-thead-original-thead">
                                                <tr>
                                                    <th scope="col" width="1" className="token-sticky-header">
                                                        <div className="sticky-thead-original-th-inner-wrapper">#</div>
                                                    </th>
                                                    <th scope="col" className="token-sticky-header">
                                                        <div className="sticky-thead-original-th-inner-wrapper">Collection</div>
                                                    </th>
                                                    <th scope="col" width="100" className="text-nowrap">
                                                        <div className="sticky-thead-original-th-inner-wrapper">
                                                            <i className="fa fa-angle-down" style={{ color: 'gray' }}></i>{' '}
                                                            <a href="/tokens-nft?sort=24h&amp;order=asc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for ascending sort">
                                                                Transfers (24H)
                                                            </a>
                                                        </div>
                                                    </th>
                                                    <th scope="col" width="100" className="text-nowrap">
                                                        <div className="sticky-thead-original-th-inner-wrapper">
                                                            <a href="/tokens-nft?sort=7d&amp;order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                                                                Transfers (7D)
                                                            </a>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="token-sticky-header">
                                                        <div className="sticky-thead-original-th-inner-wrapper">Owners</div>
                                                    </th>
                                                    <th scope="col" className="token-sticky-header">
                                                        <div className="sticky-thead-original-th-inner-wrapper">Total Assets</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map(item => {
                                                    return (
                                                        <tr key={item.order_no}>
                                                            <td>{item.order_no}</td>
                                                            <td>
                                                                <div className="media">
                                                                    <img className="u-xs-avatar mr-2" src="/images/main/empty-token.png" />
                                                                    <div className="media-body">
                                                                        <h3 className="h6 mb-0">
                                                                            <div className="token-wrap">
                                                                                {
                                                                                    item.token_name ? (<Tooltip title={item.token_address}>
                                                                                        <a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                                                                            {item.token_name}
                                                                                        </a></Tooltip>) : (<a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                                                                            {item.token_name}
                                                                                        </a>)
                                                                                }
                                                                            </div>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item.transfers_24h}</td>
                                                            <td>{item.transfers_seven_day}</td>
                                                            <td>{item.owners}</td>
                                                            <td>{item.total_assets}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <form method="post" action="./top-nfts">
                                    <div className="d-md-flex justify-content-between align-items-center my-3">
                                        <div className="d-flex align-items-center text-secondary mb-3 mb-md-0">
                                            Show
                                            <select name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage" defaultValue={length} className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            Records
                                        </div>
                                        <Pager path="/top-nfts" current={state.body.start} total={totalPage} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
